import { generatedApi as api } from './generated-api';
export const addTagTypes = [
	'Material_catalog endpoints',
	'Operation_type_catalog endpoints',
	'Product_operation endpoints',
	'Product_catalog endpoints',
	'Product_tech_map endpoints',
	'Storage_log endpoints',
	'User endpoints',
	'Auth endpoints',
	'Num_catalog endpoints',
	'ProductSGDOut endpoints',
	'ProductPlan endpoints',
	'Custom material endpoints',
	'Custom product endpoints',
	'CustomNum endpoints',
	'Employee_catalog endpoints',
	'Tech_map_symbol_catalog endpoints',
	'Admin endpoints',
	'Issue endpoints',
	'notification endpoints',
	'File endpoints',
	'Supplier catalog endpoints',
	'Manufacturer catalog endpoints',
	'Invoice endpoints',
	'Assembly_catalog endpoints',
	'Assembly tech map endpoints',
	'Preform_catalog endpoints',
	'Preform tech map endpoints',
	'Tech map all endpoints',
	'development',
] as const;
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			getMaterials: build.query<GetMaterialsApiResponse, GetMaterialsApiArg>({
				query: () => ({ url: `/material_catalog` }),
				providesTags: ['Material_catalog endpoints'],
			}),
			createMaterial: build.mutation<CreateMaterialApiResponse, CreateMaterialApiArg>({
				query: (queryArg) => ({ url: `/material_catalog`, method: 'POST', body: queryArg.material }),
				invalidatesTags: ['Material_catalog endpoints'],
			}),
			getMaterial: build.query<GetMaterialApiResponse, GetMaterialApiArg>({
				query: (queryArg) => ({ url: `/material_catalog/${queryArg.materialId}` }),
				providesTags: ['Material_catalog endpoints'],
			}),
			getOperationTypes: build.query<GetOperationTypesApiResponse, GetOperationTypesApiArg>({
				query: () => ({ url: `/operation_type_catalog` }),
				providesTags: ['Operation_type_catalog endpoints'],
			}),
			createOperationType: build.mutation<CreateOperationTypeApiResponse, CreateOperationTypeApiArg>({
				query: (queryArg) => ({
					url: `/operation_type_catalog`,
					method: 'POST',
					body: queryArg.operationTypeCatalogCreate,
				}),
				invalidatesTags: ['Operation_type_catalog endpoints'],
			}),
			getOperationType: build.query<GetOperationTypeApiResponse, GetOperationTypeApiArg>({
				query: (queryArg) => ({ url: `/operation_type_catalog/${queryArg.operationTypeId}` }),
				providesTags: ['Operation_type_catalog endpoints'],
			}),
			getOperations: build.query<GetOperationsApiResponse, GetOperationsApiArg>({
				query: () => ({ url: `/product_operation` }),
				providesTags: ['Product_operation endpoints'],
			}),
			updateOperations: build.mutation<UpdateOperationsApiResponse, UpdateOperationsApiArg>({
				query: (queryArg) => ({
					url: `/product_operation`,
					method: 'PUT',
					body: queryArg.bodyUpdateOperations,
				}),
				invalidatesTags: ['Product_operation endpoints'],
			}),
			createOperation: build.mutation<CreateOperationApiResponse, CreateOperationApiArg>({
				query: (queryArg) => ({
					url: `/product_operation`,
					method: 'POST',
					body: queryArg.productOperationCreate,
				}),
				invalidatesTags: ['Product_operation endpoints'],
			}),
			getDefaultOperations: build.query<GetDefaultOperationsApiResponse, GetDefaultOperationsApiArg>({
				query: () => ({ url: `/product_operation/default` }),
				providesTags: ['Product_operation endpoints'],
			}),
			getCurrentOperationsNames: build.query<
				GetCurrentOperationsNamesApiResponse,
				GetCurrentOperationsNamesApiArg
			>({
				query: () => ({ url: `/product_operation/current_list` }),
				providesTags: ['Product_operation endpoints'],
			}),
			getOperation: build.query<GetOperationApiResponse, GetOperationApiArg>({
				query: (queryArg) => ({ url: `/product_operation/${queryArg.productOperationId}` }),
				providesTags: ['Product_operation endpoints'],
			}),
			getOperationLinkHistory: build.query<GetOperationLinkHistoryApiResponse, GetOperationLinkHistoryApiArg>({
				query: (queryArg) => ({ url: `/product_operation/link_history/${queryArg.productOperationId}` }),
				providesTags: ['Product_operation endpoints'],
			}),
			updateOperationsOperator: build.mutation<
				UpdateOperationsOperatorApiResponse,
				UpdateOperationsOperatorApiArg
			>({
				query: (queryArg) => ({
					url: `/product_operation/operator`,
					method: 'PUT',
					body: queryArg.bodyUpdateOperationsOperator,
				}),
				invalidatesTags: ['Product_operation endpoints'],
			}),
			cancelOperation: build.mutation<CancelOperationApiResponse, CancelOperationApiArg>({
				query: (queryArg) => ({
					url: `/product_operation/cancel`,
					method: 'POST',
					body: queryArg.cancelProductOperationRequest,
				}),
				invalidatesTags: ['Product_operation endpoints'],
			}),
			renewalOperation: build.mutation<RenewalOperationApiResponse, RenewalOperationApiArg>({
				query: (queryArg) => ({
					url: `/product_operation/renewal`,
					method: 'POST',
					body: queryArg.renewalProductOperationRequest,
				}),
				invalidatesTags: ['Product_operation endpoints'],
			}),
			getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						name_strict: queryArg.nameStrict,
						symbol_strict: queryArg.symbolStrict,
						standard_consumption: queryArg.standardConsumption,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			updateProduct: build.mutation<UpdateProductApiResponse, UpdateProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog`, method: 'PUT', body: queryArg.bodyUpdateProduct }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			createProduct: build.mutation<CreateProductApiResponse, CreateProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog`, method: 'POST', body: queryArg.bodyCreateProduct }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getProduct: build.query<GetProductApiResponse, GetProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.productId}` }),
				providesTags: ['Product_catalog endpoints'],
			}),
			deleteProduct: build.mutation<DeleteProductApiResponse, DeleteProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.productId}`, method: 'DELETE' }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getProductNamesForFilter: build.query<GetProductNamesForFilterApiResponse, GetProductNamesForFilterApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog/filter/name`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			getProductSymbolForFilter: build.query<
				GetProductSymbolForFilterApiResponse,
				GetProductSymbolForFilterApiArg
			>({
				query: (queryArg) => ({
					url: `/product_catalog/filter/symbol`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			addFileToDetail: build.mutation<AddFileToDetailApiResponse, AddFileToDetailApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog/${queryArg.id}/file`,
					method: 'POST',
					body: queryArg.bodyAddFileToDetail,
				}),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			deleteFileForDetail: build.mutation<DeleteFileForDetailApiResponse, DeleteFileForDetailApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.id}/file`, method: 'DELETE' }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getTechMaps: build.query<GetTechMapsApiResponse, GetTechMapsApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						product_name_strict: queryArg.productNameStrict,
						product_symbol_strict: queryArg.productSymbolStrict,
						product_name: queryArg.productName,
						product_symbol: queryArg.productSymbol,
						num_strict: queryArg.numStrict,
						statuses: queryArg.statuses,
						commented: queryArg.commented,
						wasted: queryArg.wasted,
						count_number_from: queryArg.countNumberFrom,
						count_number_to: queryArg.countNumberTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Product_tech_map endpoints'],
			}),
			updateTechMap: build.mutation<UpdateTechMapApiResponse, UpdateTechMapApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map`,
					method: 'PUT',
					body: queryArg.productTechMapUpdateRequest,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			createTechMap: build.mutation<CreateTechMapApiResponse, CreateTechMapApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map`,
					method: 'POST',
					body: queryArg.productTechMapRequestCreate,
					headers: {
						'x-disable-catalog-num-remainder-check': queryArg['x-disable-catalog-num-remainder-check'],
					},
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getAllProductTechMapsForLinkPreformTechMap: build.query<
				GetAllProductTechMapsForLinkPreformTechMapApiResponse,
				GetAllProductTechMapsForLinkPreformTechMapApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/for_link_preform_tech_map`,
					params: { string_to_search: queryArg.stringToSearch, num: queryArg.num },
				}),
				providesTags: ['Product_tech_map endpoints'],
			}),
			getUniqueProductCustomNums: build.query<
				GetUniqueProductCustomNumsApiResponse,
				GetUniqueProductCustomNumsApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/custom_num`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Product_tech_map endpoints'],
			}),
			getTechMap: build.query<GetTechMapApiResponse, GetTechMapApiArg>({
				query: (queryArg) => ({ url: `/product_tech_map/${queryArg.productTechMapId}` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			getTechMapGraph: build.query<GetTechMapGraphApiResponse, GetTechMapGraphApiArg>({
				query: (queryArg) => ({ url: `/product_tech_map/get_graph/number/${queryArg.productTechMapNumber}` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			getTechMapByNumber: build.query<GetTechMapByNumberApiResponse, GetTechMapByNumberApiArg>({
				query: (queryArg) => ({ url: `/product_tech_map/number/${queryArg.productTechMapNumber}` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			changeStatus: build.mutation<ChangeStatusApiResponse, ChangeStatusApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/change_status`,
					method: 'POST',
					body: queryArg.productTechMapChangeStatusRequest,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getTechMapExcel: build.mutation<GetTechMapExcelApiResponse, GetTechMapExcelApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/get_excel/${queryArg.productTechMapNumber}`,
					method: 'GET',
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			createLinkedTechMapWithNew: build.mutation<
				CreateLinkedTechMapWithNewApiResponse,
				CreateLinkedTechMapWithNewApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/linked/new`,
					method: 'POST',
					body: queryArg.productTechMapLinkedRequestWithNewCreate,
					headers: {
						'x-disable-catalog-num-remainder-check': queryArg['x-disable-catalog-num-remainder-check'],
					},
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			createLinkedTechMapWithExisted: build.mutation<
				CreateLinkedTechMapWithExistedApiResponse,
				CreateLinkedTechMapWithExistedApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/linked/existed`,
					method: 'POST',
					body: queryArg.productTechMapLinkedRequestWithExistedCreate,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getBoundariesForUpdatingLinkTechMap: build.query<
				GetBoundariesForUpdatingLinkTechMapApiResponse,
				GetBoundariesForUpdatingLinkTechMapApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/linked/available_update_boundaries/${queryArg.linkedProductOperationsId}`,
				}),
				providesTags: ['Product_tech_map endpoints'],
			}),
			updateLinkTechMap: build.mutation<UpdateLinkTechMapApiResponse, UpdateLinkTechMapApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/linked`,
					method: 'PUT',
					body: queryArg.productTechMapLinkUpdateRequest,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			deleteLinkTechMap: build.mutation<DeleteLinkTechMapApiResponse, DeleteLinkTechMapApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/linked/${queryArg.linkedProductOperationsId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getTechMapListExcel: build.mutation<GetTechMapListExcelApiResponse, GetTechMapListExcelApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/get_excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						product_name_strict: queryArg.productNameStrict,
						product_symbol_strict: queryArg.productSymbolStrict,
						product_name: queryArg.productName,
						product_symbol: queryArg.productSymbol,
						num_strict: queryArg.numStrict,
						statuses: queryArg.statuses,
						commented: queryArg.commented,
						wasted: queryArg.wasted,
						count_number_from: queryArg.countNumberFrom,
						count_number_to: queryArg.countNumberTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
					},
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getTechMapStatusList: build.query<GetTechMapStatusListApiResponse, GetTechMapStatusListApiArg>({
				query: () => ({ url: `/product_tech_map/status/list/` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			getInProgressTechMaps: build.query<GetInProgressTechMapsApiResponse, GetInProgressTechMapsApiArg>({
				query: () => ({ url: `/product_tech_map/in_progress/` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			changeProductTechMapFile: build.mutation<
				ChangeProductTechMapFileApiResponse,
				ChangeProductTechMapFileApiArg
			>({
				query: (queryArg) => ({
					url: `/product_tech_map/${queryArg.id}/file`,
					method: 'PUT',
					body: queryArg.bodyChangeProductTechMapFile,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			createTechMapPrintPdf: build.mutation<CreateTechMapPrintPdfApiResponse, CreateTechMapPrintPdfApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/${queryArg.id}/print`,
					method: 'POST',
					body: queryArg.bodyCreateTechMapPrintPdf,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getProductTechMapComments: build.query<
				GetProductTechMapCommentsApiResponse,
				GetProductTechMapCommentsApiArg
			>({
				query: (queryArg) => ({ url: `/product_tech_map/${queryArg.productTechMapId}/comments` }),
				providesTags: ['Product_tech_map endpoints'],
			}),
			createCommentForTechMap: build.mutation<CreateCommentForTechMapApiResponse, CreateCommentForTechMapApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/${queryArg.productTechMapId}/comments`,
					method: 'POST',
					body: queryArg.productTechMapCommentCreateRequest,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			changeCommentText: build.mutation<ChangeCommentTextApiResponse, ChangeCommentTextApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/${queryArg.productTechMapId}/comments/${queryArg.productCommentId}`,
					method: 'PUT',
					body: queryArg.productTechMapCommentUpdateTextRequest,
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			deleteComment: build.mutation<DeleteCommentApiResponse, DeleteCommentApiArg>({
				query: (queryArg) => ({
					url: `/product_tech_map/${queryArg.productTechMapId}/comments/${queryArg.productCommentId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Product_tech_map endpoints'],
			}),
			getStorageLogs: build.query<GetStorageLogsApiResponse, GetStorageLogsApiArg>({
				query: () => ({ url: `/storage_log` }),
				providesTags: ['Storage_log endpoints'],
			}),
			updateStorageLog: build.mutation<UpdateStorageLogApiResponse, UpdateStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log`, method: 'PUT', body: queryArg.storageLogUpdate }),
				invalidatesTags: ['Storage_log endpoints'],
			}),
			createStorageLog: build.mutation<CreateStorageLogApiResponse, CreateStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log`, method: 'POST', body: queryArg.storageLogCreate }),
				invalidatesTags: ['Storage_log endpoints'],
			}),
			getStorageLog: build.query<GetStorageLogApiResponse, GetStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log/${queryArg.storageLogId}` }),
				providesTags: ['Storage_log endpoints'],
			}),
			getProfile: build.query<GetProfileApiResponse, GetProfileApiArg>({
				query: () => ({ url: `/user/profile` }),
				providesTags: ['User endpoints'],
			}),
			userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
				query: (queryArg) => ({ url: `/user/profile`, method: 'PUT', body: queryArg.userUpdateRequest }),
				invalidatesTags: ['User endpoints'],
			}),
			userChangePassword: build.mutation<UserChangePasswordApiResponse, UserChangePasswordApiArg>({
				query: (queryArg) => ({
					url: `/user/change_password`,
					method: 'PUT',
					body: queryArg.userUpdatePasswordRequest,
				}),
				invalidatesTags: ['User endpoints'],
			}),
			auth: build.mutation<AuthApiResponse, AuthApiArg>({
				query: (queryArg) => ({ url: `/login`, method: 'POST', body: queryArg.bodyAuth }),
				invalidatesTags: ['Auth endpoints'],
			}),
			refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
				query: (queryArg) => ({ url: `/refresh-token`, method: 'POST', body: queryArg.refreshToken }),
				invalidatesTags: ['Auth endpoints'],
			}),
			logout: build.query<LogoutApiResponse, LogoutApiArg>({
				query: () => ({ url: `/logout` }),
				providesTags: ['Auth endpoints'],
			}),
			resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
				query: (queryArg) => ({ url: `/reset_password`, method: 'POST', params: { email: queryArg.email } }),
				invalidatesTags: ['Auth endpoints'],
			}),
			authChangePassword: build.mutation<AuthChangePasswordApiResponse, AuthChangePasswordApiArg>({
				query: (queryArg) => ({
					url: `/change_password`,
					method: 'POST',
					params: { reset_password_hash: queryArg.resetPasswordHash, new_password: queryArg.newPassword },
				}),
				invalidatesTags: ['Auth endpoints'],
			}),
			getNums: build.query<GetNumsApiResponse, GetNumsApiArg>({
				query: (queryArg) => ({
					url: `/num_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						standard_consumption_type: queryArg.standardConsumptionType,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Num_catalog endpoints'],
			}),
			putNumCatalog: build.mutation<PutNumCatalogApiResponse, PutNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog`, method: 'PUT', body: queryArg.numCatalogUpdateRequest }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			postNumCatalog: build.mutation<PostNumCatalogApiResponse, PostNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog`, method: 'POST', body: queryArg.numCatalogCreateRequest }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			getNumById: build.query<GetNumByIdApiResponse, GetNumByIdApiArg>({
				query: (queryArg) => ({ url: `/num_catalog/${queryArg.numCatalogId}` }),
				providesTags: ['Num_catalog endpoints'],
			}),
			deleteNumCatalog: build.mutation<DeleteNumCatalogApiResponse, DeleteNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog/${queryArg.numCatalogId}`, method: 'DELETE' }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			getNumCatalogListExcel: build.mutation<GetNumCatalogListExcelApiResponse, GetNumCatalogListExcelApiArg>({
				query: (queryArg) => ({
					url: `/num_catalog/excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			putSgdOut: build.mutation<PutSgdOutApiResponse, PutSgdOutApiArg>({
				query: (queryArg) => ({ url: `/product_sgd_out`, method: 'PUT', body: queryArg.payload }),
				invalidatesTags: ['ProductSGDOut endpoints'],
			}),
			postSgdOut: build.mutation<PostSgdOutApiResponse, PostSgdOutApiArg>({
				query: (queryArg) => ({ url: `/product_sgd_out`, method: 'POST', body: queryArg.payload }),
				invalidatesTags: ['ProductSGDOut endpoints'],
			}),
			putSgdOutOperator: build.mutation<PutSgdOutOperatorApiResponse, PutSgdOutOperatorApiArg>({
				query: (queryArg) => ({
					url: `/product_sgd_out/operator`,
					method: 'PUT',
					body: queryArg.bodyPutSgdOutOperator,
				}),
				invalidatesTags: ['ProductSGDOut endpoints'],
			}),
			patchPlanOperator: build.mutation<PatchPlanOperatorApiResponse, PatchPlanOperatorApiArg>({
				query: (queryArg) => ({
					url: `/product_plan/operator`,
					method: 'PUT',
					body: queryArg.productPlanUpdateOperatorRequest,
				}),
				invalidatesTags: ['ProductPlan endpoints'],
			}),
			putCustomMaterial: build.mutation<PutCustomMaterialApiResponse, PutCustomMaterialApiArg>({
				query: (queryArg) => ({
					url: `/custom_material`,
					method: 'PUT',
					body: queryArg.customMaterialRequestUpdate,
				}),
				invalidatesTags: ['Custom material endpoints'],
			}),
			putCustomProduct: build.mutation<PutCustomProductApiResponse, PutCustomProductApiArg>({
				query: (queryArg) => ({
					url: `/custom_product`,
					method: 'PUT',
					body: queryArg.customProductRequestUpdate,
				}),
				invalidatesTags: ['Custom product endpoints'],
			}),
			updateNum: build.mutation<UpdateNumApiResponse, UpdateNumApiArg>({
				query: (queryArg) => ({
					url: `/custom_num`,
					method: 'PUT',
					body: queryArg.customNumUpdateRequest,
					params: { x_disable_catalog_num_remainder_check: queryArg.xDisableCatalogNumRemainderCheck },
				}),
				invalidatesTags: ['CustomNum endpoints'],
			}),
			getEmployees: build.query<GetEmployeesApiResponse, GetEmployeesApiArg>({
				query: () => ({ url: `/employee_catalog` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getEmployeeInitials: build.query<GetEmployeeInitialsApiResponse, GetEmployeeInitialsApiArg>({
				query: () => ({ url: `/employee_catalog/initials` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getEmployee: build.query<GetEmployeeApiResponse, GetEmployeeApiArg>({
				query: (queryArg) => ({ url: `/employee_catalog/${queryArg.employeeId}` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getTechMapSymbolCatalog: build.query<GetTechMapSymbolCatalogApiResponse, GetTechMapSymbolCatalogApiArg>({
				query: () => ({ url: `/tech_map_symbol_catalog` }),
				providesTags: ['Tech_map_symbol_catalog endpoints'],
			}),
			updateMaterial: build.mutation<UpdateMaterialApiResponse, UpdateMaterialApiArg>({
				query: (queryArg) => ({
					url: `/admin/material_catalog`,
					method: 'PUT',
					body: queryArg.materialCatalogUpdate,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			updateOperationType: build.mutation<UpdateOperationTypeApiResponse, UpdateOperationTypeApiArg>({
				query: (queryArg) => ({
					url: `/admin/operation_type_catalog`,
					method: 'PUT',
					body: queryArg.operationTypeCatalogUpdate,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			putPlan: build.mutation<PutPlanApiResponse, PutPlanApiArg>({
				query: (queryArg) => ({
					url: `/admin/product_plan`,
					method: 'PUT',
					body: queryArg.productPlanUpdateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			cancelTechmap: build.mutation<CancelTechmapApiResponse, CancelTechmapApiArg>({
				query: (queryArg) => ({
					url: `/admin/product_tech_map/cancel`,
					method: 'POST',
					body: queryArg.productTechMapChangeStatusAdminRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			deleteTechmap: build.mutation<DeleteTechmapApiResponse, DeleteTechmapApiArg>({
				query: (queryArg) => ({
					url: `/admin/product_tech_map/`,
					method: 'DELETE',
					params: { product_tech_map_number: queryArg.productTechMapNumber },
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						role: queryArg.role,
						is_active: queryArg.isActive,
						only_users: queryArg.onlyUsers,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Admin endpoints'],
			}),
			updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/`, method: 'PUT', body: queryArg.userUpdateAdminRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/`, method: 'POST', body: queryArg.userCreateRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
				query: (queryArg) => ({ url: `/admin/user/${queryArg.id}` }),
				providesTags: ['Admin endpoints'],
			}),
			deleteUserById: build.mutation<DeleteUserByIdApiResponse, DeleteUserByIdApiArg>({
				query: (queryArg) => ({ url: `/admin/user/${queryArg.id}`, method: 'DELETE' }),
				invalidatesTags: ['Admin endpoints'],
			}),
			createUserExecutor: build.mutation<CreateUserExecutorApiResponse, CreateUserExecutorApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/executor`,
					method: 'POST',
					body: queryArg.userExecutorCreateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			fireUser: build.mutation<FireUserApiResponse, FireUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/fire`, method: 'POST', body: queryArg.userFireRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			restoreUser: build.mutation<RestoreUserApiResponse, RestoreUserApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/restore`,
					method: 'POST',
					body: queryArg.userRestoreRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			activateUser: build.mutation<ActivateUserApiResponse, ActivateUserApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/activate`,
					method: 'POST',
					body: queryArg.userActivateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUserRolesMapping: build.query<GetUserRolesMappingApiResponse, GetUserRolesMappingApiArg>({
				query: () => ({ url: `/admin/user/mapping/role` }),
				providesTags: ['Admin endpoints'],
			}),
			updateEmployeeCatalog: build.mutation<UpdateEmployeeCatalogApiResponse, UpdateEmployeeCatalogApiArg>({
				query: (queryArg) => ({
					url: `/admin/employee_catalog`,
					method: 'PUT',
					body: queryArg.employeeCatalogUpdateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			createEmployeeCatalog: build.mutation<CreateEmployeeCatalogApiResponse, CreateEmployeeCatalogApiArg>({
				query: (queryArg) => ({ url: `/admin/employee_catalog`, method: 'POST', body: queryArg.employee }),
				invalidatesTags: ['Admin endpoints'],
			}),
			deleteEmployeeCatalog: build.mutation<DeleteEmployeeCatalogApiResponse, DeleteEmployeeCatalogApiArg>({
				query: (queryArg) => ({
					url: `/admin/employee_catalog/${queryArg.employeeCatalogId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getIssues: build.query<GetIssuesApiResponse, GetIssuesApiArg>({
				query: (queryArg) => ({
					url: `/issue`,
					params: {
						string_to_search: queryArg.stringToSearch,
						type: queryArg['type'],
						status: queryArg.status,
						executor_user_id: queryArg.executorUserId,
						responsible_user_id: queryArg.responsibleUserId,
						product_symbol: queryArg.productSymbol,
						open_at_from: queryArg.openAtFrom,
						open_at_to: queryArg.openAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Issue endpoints'],
			}),
			getIssueStuckOperationDetails: build.query<
				GetIssueStuckOperationDetailsApiResponse,
				GetIssueStuckOperationDetailsApiArg
			>({
				query: (queryArg) => ({ url: `/issue/stuck_product_operation/${queryArg.issueId}/details` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueWastedLimitDetails: build.query<
				GetIssueWastedLimitDetailsApiResponse,
				GetIssueWastedLimitDetailsApiArg
			>({
				query: (queryArg) => ({ url: `/issue/wasted_limit/${queryArg.issueId}/details` }),
				providesTags: ['Issue endpoints'],
			}),
			createCommentForIssue: build.mutation<CreateCommentForIssueApiResponse, CreateCommentForIssueApiArg>({
				query: (queryArg) => ({
					url: `/issue/${queryArg.issueId}/comment`,
					method: 'POST',
					body: queryArg.issueCommentCreateRequest,
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			changeIssueStatusToProgress: build.mutation<
				ChangeIssueStatusToProgressApiResponse,
				ChangeIssueStatusToProgressApiArg
			>({
				query: (queryArg) => ({ url: `/issue/${queryArg.issueId}/progress`, method: 'POST' }),
				invalidatesTags: ['Issue endpoints'],
			}),
			changeIssueStatusToDone: build.mutation<ChangeIssueStatusToDoneApiResponse, ChangeIssueStatusToDoneApiArg>({
				query: (queryArg) => ({
					url: `/issue/${queryArg.issueId}/done`,
					method: 'POST',
					body: queryArg.issueReportUpdateStatusToClosedRequest,
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			getIssueStatusMapping: build.query<GetIssueStatusMappingApiResponse, GetIssueStatusMappingApiArg>({
				query: () => ({ url: `/issue/mapping/status` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueTypeMapping: build.query<GetIssueTypeMappingApiResponse, GetIssueTypeMappingApiArg>({
				query: () => ({ url: `/issue/mapping/type` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueListExcel: build.mutation<GetIssueListExcelApiResponse, GetIssueListExcelApiArg>({
				query: (queryArg) => ({
					url: `/issue/excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						type: queryArg['type'],
						status: queryArg.status,
						executor_user_id: queryArg.executorUserId,
						responsible_user_id: queryArg.responsibleUserId,
						product_symbol: queryArg.productSymbol,
						open_at_from: queryArg.openAtFrom,
						open_at_to: queryArg.openAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			getUserNotifications: build.query<GetUserNotificationsApiResponse, GetUserNotificationsApiArg>({
				query: () => ({ url: `/notification` }),
				providesTags: ['notification endpoints'],
			}),
			getNotificationTypeMapping: build.query<
				GetNotificationTypeMappingApiResponse,
				GetNotificationTypeMappingApiArg
			>({
				query: () => ({ url: `/notification/mapping/type` }),
				providesTags: ['notification endpoints'],
			}),
			getCountOfUnread: build.query<GetCountOfUnreadApiResponse, GetCountOfUnreadApiArg>({
				query: () => ({ url: `/notification/unread_count` }),
				providesTags: ['notification endpoints'],
			}),
			readNotification: build.mutation<ReadNotificationApiResponse, ReadNotificationApiArg>({
				query: (queryArg) => ({
					url: `/notification/read`,
					method: 'POST',
					params: { notification_id: queryArg.notificationId },
				}),
				invalidatesTags: ['notification endpoints'],
			}),
			getFile: build.query<GetFileApiResponse, GetFileApiArg>({
				query: (queryArg) => ({ url: `/file/${queryArg.id}` }),
				providesTags: ['File endpoints'],
			}),
			getSuppliers: build.query<GetSuppliersApiResponse, GetSuppliersApiArg>({
				query: (queryArg) => ({
					url: `/supplier_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Supplier catalog endpoints'],
			}),
			getManufactures: build.query<GetManufacturesApiResponse, GetManufacturesApiArg>({
				query: (queryArg) => ({
					url: `/manufacturer_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Manufacturer catalog endpoints'],
			}),
			getInvoicesFilterByNumber: build.query<
				GetInvoicesFilterByNumberApiResponse,
				GetInvoicesFilterByNumberApiArg
			>({
				query: (queryArg) => ({
					url: `/invoicefilter/number`,
					params: {
						string_to_search: queryArg.stringToSearch,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Invoice endpoints'],
			}),
			getInvoicesFilterByDate: build.query<GetInvoicesFilterByDateApiResponse, GetInvoicesFilterByDateApiArg>({
				query: (queryArg) => ({
					url: `/invoicefilter/date`,
					params: {
						filter_date: queryArg.filterDate,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Invoice endpoints'],
			}),
			getAssemblies: build.query<GetAssembliesApiResponse, GetAssembliesApiArg>({
				query: (queryArg) => ({
					url: `/assembly_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Assembly_catalog endpoints'],
			}),
			createAssembly: build.mutation<CreateAssemblyApiResponse, CreateAssemblyApiArg>({
				query: (queryArg) => ({
					url: `/assembly_catalog`,
					method: 'POST',
					body: queryArg.assemblyCatalogCreateRequest,
				}),
				invalidatesTags: ['Assembly_catalog endpoints'],
			}),
			getAssemblyById: build.query<GetAssemblyByIdApiResponse, GetAssemblyByIdApiArg>({
				query: (queryArg) => ({ url: `/assembly_catalog/${queryArg.assemblyId}` }),
				providesTags: ['Assembly_catalog endpoints'],
			}),
			updateAssembly: build.mutation<UpdateAssemblyApiResponse, UpdateAssemblyApiArg>({
				query: (queryArg) => ({
					url: `/assembly_catalog/${queryArg.assemblyId}`,
					method: 'PUT',
					body: queryArg.assemblyCatalogUpdateRequest,
				}),
				invalidatesTags: ['Assembly_catalog endpoints'],
			}),
			deleteAssembly: build.mutation<DeleteAssemblyApiResponse, DeleteAssemblyApiArg>({
				query: (queryArg) => ({ url: `/assembly_catalog/${queryArg.assemblyId}`, method: 'DELETE' }),
				invalidatesTags: ['Assembly_catalog endpoints'],
			}),
			addFileToAssembly: build.mutation<AddFileToAssemblyApiResponse, AddFileToAssemblyApiArg>({
				query: (queryArg) => ({
					url: `/assembly_catalog/${queryArg.assemblyId}/file`,
					method: 'POST',
					body: queryArg.bodyAddFileToAssembly,
				}),
				invalidatesTags: ['Assembly_catalog endpoints'],
			}),
			deleteFileForAssembly: build.mutation<DeleteFileForAssemblyApiResponse, DeleteFileForAssemblyApiArg>({
				query: (queryArg) => ({ url: `/assembly_catalog/${queryArg.assemblyId}/file`, method: 'DELETE' }),
				invalidatesTags: ['Assembly_catalog endpoints'],
			}),
			getAssemblyTechMaps: build.query<GetAssemblyTechMapsApiResponse, GetAssemblyTechMapsApiArg>({
				query: (queryArg) => ({
					url: `/assembly_tech_map`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						assembly_name_strict: queryArg.assemblyNameStrict,
						assembly_symbol_strict: queryArg.assemblySymbolStrict,
						assembly_name: queryArg.assemblyName,
						assembly_symbol: queryArg.assemblySymbol,
						statuses: queryArg.statuses,
						commented: queryArg.commented,
						wasted: queryArg.wasted,
						accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
						accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Assembly tech map endpoints'],
			}),
			createAssemblyTechMap: build.mutation<CreateAssemblyTechMapApiResponse, CreateAssemblyTechMapApiArg>({
				query: (queryArg) => ({
					url: `/assembly_tech_map`,
					method: 'POST',
					body: queryArg.assemblyTechMapCreateRequest,
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			getAllFreeAssemblyTechMapsForLink: build.query<
				GetAllFreeAssemblyTechMapsForLinkApiResponse,
				GetAllFreeAssemblyTechMapsForLinkApiArg
			>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/for_link`,
					params: { product_symbol: queryArg.productSymbol, product_name: queryArg.productName },
				}),
				providesTags: ['Assembly tech map endpoints'],
			}),
			getAssemblyTechMapsExcel: build.query<GetAssemblyTechMapsExcelApiResponse, GetAssemblyTechMapsExcelApiArg>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/excel`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						assembly_name_strict: queryArg.assemblyNameStrict,
						assembly_symbol_strict: queryArg.assemblySymbolStrict,
						assembly_name: queryArg.assemblyName,
						assembly_symbol: queryArg.assemblySymbol,
						statuses: queryArg.statuses,
						commented: queryArg.commented,
						wasted: queryArg.wasted,
						accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
						accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				providesTags: ['Assembly tech map endpoints'],
			}),
			getAssemblyTechMapById: build.query<GetAssemblyTechMapByIdApiResponse, GetAssemblyTechMapByIdApiArg>({
				query: (queryArg) => ({ url: `/assembly_tech_map/${queryArg.assemblyTechMapId}` }),
				providesTags: ['Assembly tech map endpoints'],
			}),
			updateAssemblyTechMap: build.mutation<UpdateAssemblyTechMapApiResponse, UpdateAssemblyTechMapApiArg>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}`,
					method: 'PUT',
					body: queryArg.bodyUpdateAssemblyTechMap,
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			deleteAssemblyTechMap: build.mutation<DeleteAssemblyTechMapApiResponse, DeleteAssemblyTechMapApiArg>({
				query: (queryArg) => ({ url: `/assembly_tech_map/${queryArg.assemblyTechMapId}`, method: 'DELETE' }),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			getAssemblyTechMapByNumber: build.query<
				GetAssemblyTechMapByNumberApiResponse,
				GetAssemblyTechMapByNumberApiArg
			>({
				query: (queryArg) => ({ url: `/assembly_tech_map/number/${queryArg.assemblyTechMapNumber}` }),
				providesTags: ['Assembly tech map endpoints'],
			}),
			getAssemblyTechMapComments: build.query<
				GetAssemblyTechMapCommentsApiResponse,
				GetAssemblyTechMapCommentsApiArg
			>({
				query: (queryArg) => ({ url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/comments` }),
				providesTags: ['Assembly tech map endpoints'],
			}),
			createCommentForAssemblyTechMap: build.mutation<
				CreateCommentForAssemblyTechMapApiResponse,
				CreateCommentForAssemblyTechMapApiArg
			>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/comments`,
					method: 'POST',
					body: queryArg.assemblyTechMapCommentCreateRequest,
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			changeCommentTextForAssemblyTechMap: build.mutation<
				ChangeCommentTextForAssemblyTechMapApiResponse,
				ChangeCommentTextForAssemblyTechMapApiArg
			>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/comments/${queryArg.assemblyCommentId}`,
					method: 'PUT',
					body: queryArg.assemblyTechMapCommentUpdateTextRequest,
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			deleteCommentForAssemblyTechMap: build.mutation<
				DeleteCommentForAssemblyTechMapApiResponse,
				DeleteCommentForAssemblyTechMapApiArg
			>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/comments/${queryArg.assemblyCommentId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			changeAssemblyTechMapFile: build.mutation<
				ChangeAssemblyTechMapFileApiResponse,
				ChangeAssemblyTechMapFileApiArg
			>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/file`,
					method: 'PUT',
					body: queryArg.bodyChangeAssemblyTechMapFile,
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			cancelAssemblyTechMap: build.mutation<CancelAssemblyTechMapApiResponse, CancelAssemblyTechMapApiArg>({
				query: (queryArg) => ({
					url: `/assembly_tech_map/${queryArg.assemblyTechMapId}/cancel`,
					method: 'POST',
				}),
				invalidatesTags: ['Assembly tech map endpoints'],
			}),
			getPreforms: build.query<GetPreformsApiResponse, GetPreformsApiArg>({
				query: (queryArg) => ({
					url: `/preform_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Preform_catalog endpoints'],
			}),
			createPreform: build.mutation<CreatePreformApiResponse, CreatePreformApiArg>({
				query: (queryArg) => ({
					url: `/preform_catalog`,
					method: 'POST',
					body: queryArg.preformCatalogCreateRequest,
				}),
				invalidatesTags: ['Preform_catalog endpoints'],
			}),
			getPreformById: build.query<GetPreformByIdApiResponse, GetPreformByIdApiArg>({
				query: (queryArg) => ({ url: `/preform_catalog/${queryArg.preformId}` }),
				providesTags: ['Preform_catalog endpoints'],
			}),
			updatePreform: build.mutation<UpdatePreformApiResponse, UpdatePreformApiArg>({
				query: (queryArg) => ({
					url: `/preform_catalog/${queryArg.preformId}`,
					method: 'PUT',
					body: queryArg.preformCatalogUpdateRequest,
				}),
				invalidatesTags: ['Preform_catalog endpoints'],
			}),
			deletePreform: build.mutation<DeletePreformApiResponse, DeletePreformApiArg>({
				query: (queryArg) => ({ url: `/preform_catalog/${queryArg.preformId}`, method: 'DELETE' }),
				invalidatesTags: ['Preform_catalog endpoints'],
			}),
			addFileToPreform: build.mutation<AddFileToPreformApiResponse, AddFileToPreformApiArg>({
				query: (queryArg) => ({
					url: `/preform_catalog/${queryArg.preformId}/file`,
					method: 'POST',
					body: queryArg.bodyAddFileToPreform,
				}),
				invalidatesTags: ['Preform_catalog endpoints'],
			}),
			deleteFileForPreform: build.mutation<DeleteFileForPreformApiResponse, DeleteFileForPreformApiArg>({
				query: (queryArg) => ({ url: `/preform_catalog/${queryArg.preformId}/file`, method: 'DELETE' }),
				invalidatesTags: ['Preform_catalog endpoints'],
			}),
			getPreformTechMaps: build.query<GetPreformTechMapsApiResponse, GetPreformTechMapsApiArg>({
				query: (queryArg) => ({
					url: `/preform_tech_map`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						preform_name_strict: queryArg.preformNameStrict,
						preform_name: queryArg.preformName,
						num_strict: queryArg.numStrict,
						num_symbol_strict: queryArg.numSymbolStrict,
						num_gost_strict: queryArg.numGostStrict,
						num_sortament_strict: queryArg.numSortamentStrict,
						num_gost_sortament_strict: queryArg.numGostSortamentStrict,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
						accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Preform tech map endpoints'],
			}),
			createPreformTechMap: build.mutation<CreatePreformTechMapApiResponse, CreatePreformTechMapApiArg>({
				query: (queryArg) => ({
					url: `/preform_tech_map`,
					method: 'POST',
					body: queryArg.preformTechMapCreateRequest,
					params: { x_disable_catalog_num_remainder_check: queryArg.xDisableCatalogNumRemainderCheck },
				}),
				invalidatesTags: ['Preform tech map endpoints'],
			}),
			getPreformTechMapsExcel: build.query<GetPreformTechMapsExcelApiResponse, GetPreformTechMapsExcelApiArg>({
				query: (queryArg) => ({
					url: `/preform_tech_map/excel`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						preform_name_strict: queryArg.preformNameStrict,
						preform_name: queryArg.preformName,
						num_strict: queryArg.numStrict,
						num_symbol_strict: queryArg.numSymbolStrict,
						num_gost_strict: queryArg.numGostStrict,
						num_sortament_strict: queryArg.numSortamentStrict,
						num_gost_sortament_strict: queryArg.numGostSortamentStrict,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						accepted_at_sgd_count_from: queryArg.acceptedAtSgdCountFrom,
						accepted_at_sgd_count_to: queryArg.acceptedAtSgdCountTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				providesTags: ['Preform tech map endpoints'],
			}),
			getUniquePreformCustomNums: build.query<
				GetUniquePreformCustomNumsApiResponse,
				GetUniquePreformCustomNumsApiArg
			>({
				query: (queryArg) => ({
					url: `/preform_tech_map/custom_num`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Preform tech map endpoints'],
			}),
			getPreformTechMapById: build.query<GetPreformTechMapByIdApiResponse, GetPreformTechMapByIdApiArg>({
				query: (queryArg) => ({ url: `/preform_tech_map/${queryArg.preformTechMapId}` }),
				providesTags: ['Preform tech map endpoints'],
			}),
			updatePreformTechMap: build.mutation<UpdatePreformTechMapApiResponse, UpdatePreformTechMapApiArg>({
				query: (queryArg) => ({
					url: `/preform_tech_map/${queryArg.preformTechMapId}`,
					method: 'PUT',
					body: queryArg.bodyUpdatePreformTechMap,
					params: { x_disable_catalog_num_remainder_check: queryArg.xDisableCatalogNumRemainderCheck },
				}),
				invalidatesTags: ['Preform tech map endpoints'],
			}),
			deletePreformTechMap: build.mutation<DeletePreformTechMapApiResponse, DeletePreformTechMapApiArg>({
				query: (queryArg) => ({ url: `/preform_tech_map/${queryArg.preformTechMapId}`, method: 'DELETE' }),
				invalidatesTags: ['Preform tech map endpoints'],
			}),
			getPreformTechMapByNumber: build.query<
				GetPreformTechMapByNumberApiResponse,
				GetPreformTechMapByNumberApiArg
			>({
				query: (queryArg) => ({ url: `/preform_tech_map/number/${queryArg.preformTechMapNumber}` }),
				providesTags: ['Preform tech map endpoints'],
			}),
			changePreformTechMapFile: build.mutation<
				ChangePreformTechMapFileApiResponse,
				ChangePreformTechMapFileApiArg
			>({
				query: (queryArg) => ({
					url: `/preform_tech_map/${queryArg.preformTechMapId}/file`,
					method: 'PUT',
					body: queryArg.bodyChangePreformTechMapFile,
				}),
				invalidatesTags: ['Preform tech map endpoints'],
			}),
			cancelPreformTechMap: build.mutation<CancelPreformTechMapApiResponse, CancelPreformTechMapApiArg>({
				query: (queryArg) => ({ url: `/preform_tech_map/${queryArg.preformTechMapId}/cancel`, method: 'POST' }),
				invalidatesTags: ['Preform tech map endpoints'],
			}),
			getAllCatalogNames: build.query<GetAllCatalogNamesApiResponse, GetAllCatalogNamesApiArg>({
				query: (queryArg) => ({
					url: `/tech_map_all/names`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Tech map all endpoints'],
			}),
			getAllCatalogSymbols: build.query<GetAllCatalogSymbolsApiResponse, GetAllCatalogSymbolsApiArg>({
				query: (queryArg) => ({
					url: `/tech_map_all/symbols`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Tech map all endpoints'],
			}),
			getUniqueCustomNums: build.query<GetUniqueCustomNumsApiResponse, GetUniqueCustomNumsApiArg>({
				query: (queryArg) => ({
					url: `/tech_map_all/custom_num`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Tech map all endpoints'],
			}),
			getAllTechMaps: build.query<GetAllTechMapsApiResponse, GetAllTechMapsApiArg>({
				query: (queryArg) => ({
					url: `/tech_map_all`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						group: queryArg.group,
						name: queryArg.name,
						symbol: queryArg['symbol'],
						num_strict: queryArg.numStrict,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						commented: queryArg.commented,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Tech map all endpoints'],
			}),
			getAllTechMapsExcel: build.query<GetAllTechMapsExcelApiResponse, GetAllTechMapsExcelApiArg>({
				query: (queryArg) => ({
					url: `/tech_map_all/excel`,
					params: {
						string_to_search: queryArg.stringToSearch,
						id: queryArg.id,
						number: queryArg['number'],
						group: queryArg.group,
						name: queryArg.name,
						symbol: queryArg['symbol'],
						num_strict: queryArg.numStrict,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						commented: queryArg.commented,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				providesTags: ['Tech map all endpoints'],
			}),
			postSeedDb: build.mutation<PostSeedDbApiResponse, PostSeedDbApiArg>({
				query: () => ({ url: `/development/seed_db`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultProducts: build.mutation<SeedDefaultProductsApiResponse, SeedDefaultProductsApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_products`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultNum: build.mutation<SeedDefaultNumApiResponse, SeedDefaultNumApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_num`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultEmployee: build.mutation<SeedDefaultEmployeeApiResponse, SeedDefaultEmployeeApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_employee`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedUsers: build.mutation<SeedUsersApiResponse, SeedUsersApiArg>({
				query: () => ({ url: `/development/insert_users`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshCurrentOperation: build.mutation<RefreshCurrentOperationApiResponse, RefreshCurrentOperationApiArg>({
				query: () => ({ url: `/development/refresh/current_product_operation`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshCurrentDetailsCount: build.mutation<
				RefreshCurrentDetailsCountApiResponse,
				RefreshCurrentDetailsCountApiArg
			>({
				query: () => ({ url: `/development/refresh/current_details_count`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshWasted: build.mutation<RefreshWastedApiResponse, RefreshWastedApiArg>({
				query: () => ({ url: `/development/refresh/wasted`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshQr: build.mutation<RefreshQrApiResponse, RefreshQrApiArg>({
				query: () => ({ url: `/development/refresh/qr`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
		}),
		overrideExisting: false,
	});
export { injectedRtkApi as coreApi };
export type GetMaterialsApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain[];
export type GetMaterialsApiArg = void;
export type CreateMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type CreateMaterialApiArg = {
	material: MaterialCatalogCreateRequest;
};
export type GetMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type GetMaterialApiArg = {
	materialId: number;
};
export type GetOperationTypesApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain[];
export type GetOperationTypesApiArg = void;
export type CreateOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type CreateOperationTypeApiArg = {
	operationTypeCatalogCreate: OperationTypeCatalogCreate;
};
export type GetOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type GetOperationTypeApiArg = {
	operationTypeId: number;
};
export type GetOperationsApiResponse = /** status 200 Successful Response */ ProductOperationResponse[];
export type GetOperationsApiArg = void;
export type UpdateOperationsApiResponse = /** status 200 Successful Response */ ProductOperationResponse[];
export type UpdateOperationsApiArg = {
	bodyUpdateOperations: BodyUpdateOperations;
};
export type CreateOperationApiResponse = /** status 200 Successful Response */ ProductOperationResponse;
export type CreateOperationApiArg = {
	productOperationCreate: ProductOperationCreate;
};
export type GetDefaultOperationsApiResponse = /** status 200 Successful Response */ DefaultProductOperationResponse[];
export type GetDefaultOperationsApiArg = void;
export type GetCurrentOperationsNamesApiResponse = /** status 200 Successful Response */ any;
export type GetCurrentOperationsNamesApiArg = void;
export type GetOperationApiResponse = /** status 200 Successful Response */ ProductOperationResponse;
export type GetOperationApiArg = {
	productOperationId: number;
};
export type GetOperationLinkHistoryApiResponse =
	/** status 200 Successful Response */ LinkedProductOperationsResponse[];
export type GetOperationLinkHistoryApiArg = {
	productOperationId: number;
};
export type UpdateOperationsOperatorApiResponse = /** status 200 Successful Response */ ProductOperationResponse[];
export type UpdateOperationsOperatorApiArg = {
	bodyUpdateOperationsOperator: BodyUpdateOperationsOperator;
};
export type CancelOperationApiResponse = /** status 200 Successful Response */ boolean;
export type CancelOperationApiArg = {
	cancelProductOperationRequest: CancelProductOperationRequest;
};
export type RenewalOperationApiResponse = /** status 200 Successful Response */ boolean;
export type RenewalOperationApiArg = {
	renewalProductOperationRequest: RenewalProductOperationRequest;
};
export type GetProductsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsProductProductCatalogContractsProductCatalogResponse;
export type GetProductsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	nameStrict?: string;
	symbolStrict?: string;
	standardConsumption?: StandardConsumptionTypeEnum;
	pageSize?: number;
	currentPage?: number;
	orderBy?: ProductCatalogSortingFields;
	orderDir?: SortingDirection;
};
export type UpdateProductApiResponse = /** status 202 Successful Response */ ProductCatalogDomain;
export type UpdateProductApiArg = {
	bodyUpdateProduct: BodyUpdateProduct;
};
export type CreateProductApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type CreateProductApiArg = {
	bodyCreateProduct: BodyCreateProduct;
};
export type GetProductApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type GetProductApiArg = {
	productId: number;
};
export type DeleteProductApiResponse = /** status 204 Successful Response */ void;
export type DeleteProductApiArg = {
	productId: number;
};
export type GetProductNamesForFilterApiResponse = /** status 200 Successful Response */ ProductCatalogNameListResponse;
export type GetProductNamesForFilterApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetProductSymbolForFilterApiResponse =
	/** status 200 Successful Response */ ProductCatalogSymbolListResponse;
export type GetProductSymbolForFilterApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type AddFileToDetailApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type AddFileToDetailApiArg = {
	id: number;
	bodyAddFileToDetail: BodyAddFileToDetail;
};
export type DeleteFileForDetailApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type DeleteFileForDetailApiArg = {
	id: number;
};
export type GetTechMapsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsProductProductTechMapContractsProductTechMapResponse;
export type GetTechMapsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: ProductTechMapsSortingFields;
	orderDir?: SortingDirection;
	id?: number;
	number?: number;
	symbol?: string[];
	productNameStrict?: string;
	productSymbolStrict?: string;
	productName?: string;
	productSymbol?: string;
	numStrict?: number;
	statuses?: ProductTechMapStatusForFilter[];
	commented?: boolean;
	wasted?: boolean;
	countNumberFrom?: number;
	countNumberTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	pageSize?: number;
	currentPage?: number;
};
export type UpdateTechMapApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type UpdateTechMapApiArg = {
	productTechMapUpdateRequest: ProductTechMapUpdateRequest;
};
export type CreateTechMapApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type CreateTechMapApiArg = {
	'x-disable-catalog-num-remainder-check'?: string;
	productTechMapRequestCreate: ProductTechMapRequestCreate;
};
export type GetAllProductTechMapsForLinkPreformTechMapApiResponse =
	/** status 200 Successful Response */ ProductTechMapResponse[];
export type GetAllProductTechMapsForLinkPreformTechMapApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	num: number;
};
export type GetUniqueProductCustomNumsApiResponse = /** status 200 Successful Response */ NumUniqueList;
export type GetUniqueProductCustomNumsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetTechMapApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type GetTechMapApiArg = {
	productTechMapId: number;
};
export type GetTechMapGraphApiResponse = /** status 200 Successful Response */ LinkedProductOperationsItem[];
export type GetTechMapGraphApiArg = {
	productTechMapNumber: number;
};
export type GetTechMapByNumberApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type GetTechMapByNumberApiArg = {
	productTechMapNumber: number;
};
export type ChangeStatusApiResponse = /** status 200 Successful Response */ boolean;
export type ChangeStatusApiArg = {
	productTechMapChangeStatusRequest: ProductTechMapChangeStatusRequest;
};
export type GetTechMapExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetTechMapExcelApiArg = {
	productTechMapNumber: number;
};
export type CreateLinkedTechMapWithNewApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type CreateLinkedTechMapWithNewApiArg = {
	'x-disable-catalog-num-remainder-check'?: string;
	productTechMapLinkedRequestWithNewCreate: ProductTechMapLinkedRequestWithNewCreate;
};
export type CreateLinkedTechMapWithExistedApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type CreateLinkedTechMapWithExistedApiArg = {
	productTechMapLinkedRequestWithExistedCreate: ProductTechMapLinkedRequestWithExistedCreate;
};
export type GetBoundariesForUpdatingLinkTechMapApiResponse =
	/** status 200 Successful Response */ AvailableUpdateLinkBoundariesResponse;
export type GetBoundariesForUpdatingLinkTechMapApiArg = {
	linkedProductOperationsId: number;
};
export type UpdateLinkTechMapApiResponse = /** status 200 Successful Response */ any;
export type UpdateLinkTechMapApiArg = {
	productTechMapLinkUpdateRequest: ProductTechMapLinkUpdateRequest;
};
export type DeleteLinkTechMapApiResponse = /** status 200 Successful Response */ any;
export type DeleteLinkTechMapApiArg = {
	linkedProductOperationsId: number;
};
export type GetTechMapListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetTechMapListExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: ProductTechMapsSortingFields;
	orderDir?: SortingDirection;
	id?: number;
	number?: number;
	symbol?: string[];
	productNameStrict?: string;
	productSymbolStrict?: string;
	productName?: string;
	productSymbol?: string;
	numStrict?: number;
	statuses?: ProductTechMapStatusForFilter[];
	commented?: boolean;
	wasted?: boolean;
	countNumberFrom?: number;
	countNumberTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
};
export type GetTechMapStatusListApiResponse = unknown;
export type GetTechMapStatusListApiArg = void;
export type GetInProgressTechMapsApiResponse = /** status 200 Successful Response */ ProductTechMapTreeItemResponse[];
export type GetInProgressTechMapsApiArg = void;
export type ChangeProductTechMapFileApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type ChangeProductTechMapFileApiArg = {
	id: number;
	bodyChangeProductTechMapFile: BodyChangeProductTechMapFile;
};
export type CreateTechMapPrintPdfApiResponse = /** status 200 Successful Response */ ProductTechMapResponse;
export type CreateTechMapPrintPdfApiArg = {
	id: number;
	bodyCreateTechMapPrintPdf: BodyCreateTechMapPrintPdf;
};
export type GetProductTechMapCommentsApiResponse =
	/** status 200 Successful Response */ ProductTechMapCommentForUserResponse[];
export type GetProductTechMapCommentsApiArg = {
	productTechMapId: number;
};
export type CreateCommentForTechMapApiResponse =
	/** status 200 Successful Response */ ProductTechMapCommentForUserResponse[];
export type CreateCommentForTechMapApiArg = {
	productTechMapId: number;
	productTechMapCommentCreateRequest: ProductTechMapCommentCreateRequest;
};
export type ChangeCommentTextApiResponse = /** status 200 Successful Response */ ProductTechMapCommentForUserResponse[];
export type ChangeCommentTextApiArg = {
	productTechMapId: number;
	productCommentId: number;
	productTechMapCommentUpdateTextRequest: ProductTechMapCommentUpdateTextRequest;
};
export type DeleteCommentApiResponse = /** status 200 Successful Response */ ProductTechMapCommentForUserResponse[];
export type DeleteCommentApiArg = {
	productTechMapId: number;
	productCommentId: number;
};
export type GetStorageLogsApiResponse = /** status 200 Successful Response */ StorageLogDomain[];
export type GetStorageLogsApiArg = void;
export type UpdateStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type UpdateStorageLogApiArg = {
	storageLogUpdate: StorageLogUpdate;
};
export type CreateStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type CreateStorageLogApiArg = {
	storageLogCreate: StorageLogCreate;
};
export type GetStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type GetStorageLogApiArg = {
	storageLogId: number;
};
export type GetProfileApiResponse = /** status 200 Successful Response */ UserBase;
export type GetProfileApiArg = void;
export type UserUpdateApiResponse = /** status 200 Successful Response */ UserResponse;
export type UserUpdateApiArg = {
	userUpdateRequest: UserUpdateRequest;
};
export type UserChangePasswordApiResponse = /** status 200 Successful Response */ UserResponse;
export type UserChangePasswordApiArg = {
	userUpdatePasswordRequest: UserUpdatePasswordRequest;
};
export type AuthApiResponse = /** status 200 Successful Response */ Token;
export type AuthApiArg = {
	bodyAuth: BodyAuth;
};
export type RefreshTokenApiResponse = /** status 200 Successful Response */ Token;
export type RefreshTokenApiArg = {
	refreshToken: RefreshToken;
};
export type LogoutApiResponse = /** status 200 Successful Response */ any;
export type LogoutApiArg = void;
export type ResetPasswordApiResponse = /** status 200 Successful Response */ boolean;
export type ResetPasswordApiArg = {
	email: string;
};
export type AuthChangePasswordApiResponse = /** status 200 Successful Response */ boolean;
export type AuthChangePasswordApiArg = {
	resetPasswordHash: string;
	newPassword: string;
};
export type GetNumsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsNumCatalogContractsNumCatalogResponse;
export type GetNumsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	standardConsumptionType?: StandardConsumptionTypeEnum;
	orderBy?: NumCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type PutNumCatalogApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type PutNumCatalogApiArg = {
	numCatalogUpdateRequest: NumCatalogUpdateRequest;
};
export type PostNumCatalogApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type PostNumCatalogApiArg = {
	numCatalogCreateRequest: NumCatalogCreateRequest;
};
export type GetNumByIdApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type GetNumByIdApiArg = {
	numCatalogId: number;
};
export type DeleteNumCatalogApiResponse = /** status 204 Successfully deleted num_catalog */ void;
export type DeleteNumCatalogApiArg = {
	numCatalogId: number;
};
export type GetNumCatalogListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetNumCatalogListExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: NumCatalogSortingFields;
	orderDir?: SortingDirection;
};
export type PutSgdOutApiResponse = /** status 200 Successful Response */ any;
export type PutSgdOutApiArg = {
	payload: ProductSgdOutUpdateRequest[];
};
export type PostSgdOutApiResponse = /** status 200 Successful Response */ any;
export type PostSgdOutApiArg = {
	payload: ProductSgdOutCreateRequest[];
};
export type PutSgdOutOperatorApiResponse = /** status 202 Successful Response */ any;
export type PutSgdOutOperatorApiArg = {
	bodyPutSgdOutOperator: BodyPutSgdOutOperator;
};
export type PatchPlanOperatorApiResponse = /** status 200 Successful Response */ ProductPlanDomain;
export type PatchPlanOperatorApiArg = {
	productPlanUpdateOperatorRequest: ProductPlanUpdateOperatorRequest;
};
export type PutCustomMaterialApiResponse = /** status 200 Successful Response */ CustomMaterialResponse;
export type PutCustomMaterialApiArg = {
	customMaterialRequestUpdate: CustomMaterialRequestUpdate;
};
export type PutCustomProductApiResponse = /** status 200 Successful Response */ CustomProductResponse;
export type PutCustomProductApiArg = {
	customProductRequestUpdate: CustomProductRequestUpdate;
};
export type UpdateNumApiResponse = /** status 200 Successful Response */ CustomNumDomain;
export type UpdateNumApiArg = {
	xDisableCatalogNumRemainderCheck?: boolean;
	customNumUpdateRequest: CustomNumUpdateRequest;
};
export type GetEmployeesApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse[];
export type GetEmployeesApiArg = void;
export type GetEmployeeInitialsApiResponse = /** status 200 Successful Response */ EmployeeCatalogInitialsResponse[];
export type GetEmployeeInitialsApiArg = void;
export type GetEmployeeApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type GetEmployeeApiArg = {
	employeeId: number;
};
export type GetTechMapSymbolCatalogApiResponse = /** status 200 Successful Response */ TechMapSymbolCatalogDomain[];
export type GetTechMapSymbolCatalogApiArg = void;
export type UpdateMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type UpdateMaterialApiArg = {
	materialCatalogUpdate: MaterialCatalogUpdate;
};
export type UpdateOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type UpdateOperationTypeApiArg = {
	operationTypeCatalogUpdate: OperationTypeCatalogUpdate;
};
export type PutPlanApiResponse = /** status 200 Successful Response */ ProductPlanDomain;
export type PutPlanApiArg = {
	productPlanUpdateRequest: ProductPlanUpdateRequest;
};
export type CancelTechmapApiResponse = /** status 200 Successful Response */ boolean;
export type CancelTechmapApiArg = {
	productTechMapChangeStatusAdminRequest: ProductTechMapChangeStatusAdminRequest;
};
export type DeleteTechmapApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteTechmapApiArg = {
	productTechMapNumber: number;
};
export type GetUsersApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsUserContractsUserResponseAdmin;
export type GetUsersApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: UserSortingFields;
	orderDir?: SortingDirection;
	role?: UserRoleEnum;
	isActive?: boolean;
	onlyUsers?: boolean;
	pageSize?: number;
	currentPage?: number;
};
export type UpdateUserApiResponse = /** status 200 Successful Response */ UserBase;
export type UpdateUserApiArg = {
	userUpdateAdminRequest: UserUpdateAdminRequest;
};
export type CreateUserApiResponse = /** status 200 Successful Response */ UserBase;
export type CreateUserApiArg = {
	userCreateRequest: UserCreateRequest;
};
export type GetUserByIdApiResponse = /** status 200 Successful Response */ UserResponseAdmin;
export type GetUserByIdApiArg = {
	id: number;
};
export type DeleteUserByIdApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteUserByIdApiArg = {
	id: number;
};
export type CreateUserExecutorApiResponse = /** status 200 Successful Response */ UserBase;
export type CreateUserExecutorApiArg = {
	userExecutorCreateRequest: UserExecutorCreateRequest;
};
export type FireUserApiResponse = /** status 200 Successful Response */ boolean;
export type FireUserApiArg = {
	userFireRequest: UserFireRequest;
};
export type RestoreUserApiResponse = /** status 200 Successful Response */ boolean;
export type RestoreUserApiArg = {
	userRestoreRequest: UserRestoreRequest;
};
export type ActivateUserApiResponse = /** status 200 Successful Response */ boolean;
export type ActivateUserApiArg = {
	userActivateRequest: UserActivateRequest;
};
export type GetUserRolesMappingApiResponse = /** status 200 Successful Response */ {
	[key: string]: string;
};
export type GetUserRolesMappingApiArg = void;
export type UpdateEmployeeCatalogApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type UpdateEmployeeCatalogApiArg = {
	employeeCatalogUpdateRequest: EmployeeCatalogUpdateRequest;
};
export type CreateEmployeeCatalogApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type CreateEmployeeCatalogApiArg = {
	employee: EmployeeCatalogCreateRequest;
};
export type DeleteEmployeeCatalogApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteEmployeeCatalogApiArg = {
	employeeCatalogId: number;
};
export type GetIssuesApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsIssueIssueReportContractsIssueReportResponse;
export type GetIssuesApiArg = {
	/** Строка для поиска по номеру тех. карты */
	stringToSearch?: string;
	type?: IssueReportTypeEnum;
	status?: IssueReportStatusEnum;
	executorUserId?: number;
	responsibleUserId?: number;
	productSymbol?: string;
	openAtFrom?: string;
	openAtTo?: string;
	orderBy?: IssueReportSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type GetIssueStuckOperationDetailsApiResponse =
	/** status 200 Successful Response */ IssueStuckOperationResponse;
export type GetIssueStuckOperationDetailsApiArg = {
	issueId: number;
};
export type GetIssueWastedLimitDetailsApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type GetIssueWastedLimitDetailsApiArg = {
	issueId: number;
};
export type CreateCommentForIssueApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type CreateCommentForIssueApiArg = {
	issueId: number;
	issueCommentCreateRequest: IssueCommentCreateRequest;
};
export type ChangeIssueStatusToProgressApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type ChangeIssueStatusToProgressApiArg = {
	issueId: number;
};
export type ChangeIssueStatusToDoneApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type ChangeIssueStatusToDoneApiArg = {
	issueId: number;
	issueReportUpdateStatusToClosedRequest: IssueReportUpdateStatusToClosedRequest;
};
export type GetIssueStatusMappingApiResponse = unknown;
export type GetIssueStatusMappingApiArg = void;
export type GetIssueTypeMappingApiResponse = unknown;
export type GetIssueTypeMappingApiArg = void;
export type GetIssueListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetIssueListExcelApiArg = {
	/** Строка для поиска по номеру тех. карты */
	stringToSearch?: string;
	type?: IssueReportTypeEnum;
	status?: IssueReportStatusEnum;
	executorUserId?: number;
	responsibleUserId?: number;
	productSymbol?: string;
	openAtFrom?: string;
	openAtTo?: string;
	orderBy?: IssueReportSortingFields;
	orderDir?: SortingDirection;
};
export type GetUserNotificationsApiResponse = /** status 200 Successful Response */ NotificationResponse[];
export type GetUserNotificationsApiArg = void;
export type GetNotificationTypeMappingApiResponse = /** status 200 Successful Response */ {
	[key: string]: string;
};
export type GetNotificationTypeMappingApiArg = void;
export type GetCountOfUnreadApiResponse = /** status 200 Successful Response */ number;
export type GetCountOfUnreadApiArg = void;
export type ReadNotificationApiResponse = /** status 200 Successful Response */ boolean;
export type ReadNotificationApiArg = {
	notificationId: number;
};
export type GetFileApiResponse = /** status 200 Successful Response */ void;
export type GetFileApiArg = {
	id: string;
};
export type GetSuppliersApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsSupplierCatalogContractsSupplierCatalogResponse;
export type GetSuppliersApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: SupplierCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type GetManufacturesApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsManufacturerCatalogContractsManufacturerCatalogResponse;
export type GetManufacturesApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: ManufacturerCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type GetInvoicesFilterByNumberApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsInvoiceContractsInvoiceResponse;
export type GetInvoicesFilterByNumberApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	pageSize?: number;
	currentPage?: number;
};
export type GetInvoicesFilterByDateApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsInvoiceContractsInvoiceResponse;
export type GetInvoicesFilterByDateApiArg = {
	filterDate: string;
	pageSize?: number;
	currentPage?: number;
};
export type GetAssembliesApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsAssemblyAssemblyCatalogAssemblyCatalogContractsAssemblyCatalogResponse;
export type GetAssembliesApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: AssemblyCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type CreateAssemblyApiResponse = /** status 200 Successful Response */ AssemblyCatalogResponse;
export type CreateAssemblyApiArg = {
	assemblyCatalogCreateRequest: AssemblyCatalogCreateRequest;
};
export type GetAssemblyByIdApiResponse = /** status 200 Successful Response */ AssemblyCatalogResponse;
export type GetAssemblyByIdApiArg = {
	assemblyId: number;
};
export type UpdateAssemblyApiResponse = /** status 200 Successful Response */ AssemblyCatalogResponse;
export type UpdateAssemblyApiArg = {
	assemblyId: number;
	assemblyCatalogUpdateRequest: AssemblyCatalogUpdateRequest;
};
export type DeleteAssemblyApiResponse = /** status 200 Successful Response */ any;
export type DeleteAssemblyApiArg = {
	assemblyId: number;
};
export type AddFileToAssemblyApiResponse = /** status 200 Successful Response */ AssemblyCatalogResponse;
export type AddFileToAssemblyApiArg = {
	assemblyId: number;
	bodyAddFileToAssembly: BodyAddFileToAssembly;
};
export type DeleteFileForAssemblyApiResponse = /** status 200 Successful Response */ AssemblyCatalogResponse;
export type DeleteFileForAssemblyApiArg = {
	assemblyId: number;
};
export type GetAssemblyTechMapsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsAssemblyAssemblyTechMapAssemblyTechMapContractsAssemblyTechMapResponse;
export type GetAssemblyTechMapsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	symbol?: string[];
	assemblyNameStrict?: string;
	assemblySymbolStrict?: string;
	assemblyName?: string;
	assemblySymbol?: string;
	statuses?: AssemblyTechMapStatus[];
	commented?: boolean;
	wasted?: boolean;
	acceptedAtSgdCountFrom?: number;
	acceptedAtSgdCountTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	orderBy?: AssemblyTechMapsSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type CreateAssemblyTechMapApiResponse = /** status 200 Successful Response */ AssemblyTechMapResponse;
export type CreateAssemblyTechMapApiArg = {
	assemblyTechMapCreateRequest: AssemblyTechMapCreateRequest;
};
export type GetAllFreeAssemblyTechMapsForLinkApiResponse =
	/** status 200 Successful Response */ AssemblyTechMapResponse[];
export type GetAllFreeAssemblyTechMapsForLinkApiArg = {
	productSymbol: string;
	productName: string;
};
export type GetAssemblyTechMapsExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetAssemblyTechMapsExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	symbol?: string[];
	assemblyNameStrict?: string;
	assemblySymbolStrict?: string;
	assemblyName?: string;
	assemblySymbol?: string;
	statuses?: AssemblyTechMapStatus[];
	commented?: boolean;
	wasted?: boolean;
	acceptedAtSgdCountFrom?: number;
	acceptedAtSgdCountTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	orderBy?: AssemblyTechMapsSortingFields;
	orderDir?: SortingDirection;
};
export type GetAssemblyTechMapByIdApiResponse = /** status 200 Successful Response */ AssemblyTechMapResponse;
export type GetAssemblyTechMapByIdApiArg = {
	assemblyTechMapId: number;
};
export type UpdateAssemblyTechMapApiResponse = /** status 200 Successful Response */ AssemblyTechMapResponse;
export type UpdateAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
	bodyUpdateAssemblyTechMap: BodyUpdateAssemblyTechMap;
};
export type DeleteAssemblyTechMapApiResponse = /** status 200 Successful Response */ any;
export type DeleteAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
};
export type GetAssemblyTechMapByNumberApiResponse = /** status 200 Successful Response */ AssemblyTechMapResponse;
export type GetAssemblyTechMapByNumberApiArg = {
	assemblyTechMapNumber: number;
};
export type GetAssemblyTechMapCommentsApiResponse =
	/** status 200 Successful Response */ AssemblyTechMapCommentForUserResponse[];
export type GetAssemblyTechMapCommentsApiArg = {
	assemblyTechMapId: number;
};
export type CreateCommentForAssemblyTechMapApiResponse =
	/** status 200 Successful Response */ AssemblyTechMapCommentForUserResponse[];
export type CreateCommentForAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
	assemblyTechMapCommentCreateRequest: AssemblyTechMapCommentCreateRequest;
};
export type ChangeCommentTextForAssemblyTechMapApiResponse =
	/** status 200 Successful Response */ AssemblyTechMapCommentForUserResponse[];
export type ChangeCommentTextForAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
	assemblyCommentId: number;
	assemblyTechMapCommentUpdateTextRequest: AssemblyTechMapCommentUpdateTextRequest;
};
export type DeleteCommentForAssemblyTechMapApiResponse =
	/** status 200 Successful Response */ AssemblyTechMapCommentForUserResponse[];
export type DeleteCommentForAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
	assemblyCommentId: number;
};
export type ChangeAssemblyTechMapFileApiResponse = /** status 200 Successful Response */ AssemblyTechMapResponse;
export type ChangeAssemblyTechMapFileApiArg = {
	assemblyTechMapId: number;
	bodyChangeAssemblyTechMapFile: BodyChangeAssemblyTechMapFile;
};
export type CancelAssemblyTechMapApiResponse = /** status 204 Successful Response */ void;
export type CancelAssemblyTechMapApiArg = {
	assemblyTechMapId: number;
};
export type GetPreformsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsPreformPreformCatalogPreformCatalogContractsPreformCatalogResponse;
export type GetPreformsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: PreformCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type CreatePreformApiResponse = /** status 200 Successful Response */ PreformCatalogResponse;
export type CreatePreformApiArg = {
	preformCatalogCreateRequest: PreformCatalogCreateRequest;
};
export type GetPreformByIdApiResponse = /** status 200 Successful Response */ PreformCatalogResponse;
export type GetPreformByIdApiArg = {
	preformId: number;
};
export type UpdatePreformApiResponse = /** status 200 Successful Response */ PreformCatalogResponse;
export type UpdatePreformApiArg = {
	preformId: number;
	preformCatalogUpdateRequest: PreformCatalogUpdateRequest;
};
export type DeletePreformApiResponse = /** status 200 Successful Response */ any;
export type DeletePreformApiArg = {
	preformId: number;
};
export type AddFileToPreformApiResponse = /** status 200 Successful Response */ PreformCatalogResponse;
export type AddFileToPreformApiArg = {
	preformId: number;
	bodyAddFileToPreform: BodyAddFileToPreform;
};
export type DeleteFileForPreformApiResponse = /** status 200 Successful Response */ PreformCatalogResponse;
export type DeleteFileForPreformApiArg = {
	preformId: number;
};
export type GetPreformTechMapsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsPreformPreformTechMapContractsPreformTechMapResponse;
export type GetPreformTechMapsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	preformNameStrict?: string;
	preformName?: string;
	numStrict?: number;
	numSymbolStrict?: string;
	numGostStrict?: string;
	numSortamentStrict?: string;
	numGostSortamentStrict?: string;
	statuses?: PreformTechMapStatus[];
	wasted?: boolean;
	acceptedAtSgdCountFrom?: number;
	acceptedAtSgdCountTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	orderBy?: PreformTechMapsSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type CreatePreformTechMapApiResponse = /** status 200 Successful Response */ PreformTechMapResponse;
export type CreatePreformTechMapApiArg = {
	xDisableCatalogNumRemainderCheck?: boolean;
	preformTechMapCreateRequest: PreformTechMapCreateRequest;
};
export type GetPreformTechMapsExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetPreformTechMapsExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	preformNameStrict?: string;
	preformName?: string;
	numStrict?: number;
	numSymbolStrict?: string;
	numGostStrict?: string;
	numSortamentStrict?: string;
	numGostSortamentStrict?: string;
	statuses?: PreformTechMapStatus[];
	wasted?: boolean;
	acceptedAtSgdCountFrom?: number;
	acceptedAtSgdCountTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	orderBy?: PreformTechMapsSortingFields;
	orderDir?: SortingDirection;
};
export type GetUniquePreformCustomNumsApiResponse = /** status 200 Successful Response */ NumUniqueList;
export type GetUniquePreformCustomNumsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetPreformTechMapByIdApiResponse = /** status 200 Successful Response */ PreformTechMapResponse;
export type GetPreformTechMapByIdApiArg = {
	preformTechMapId: number;
};
export type UpdatePreformTechMapApiResponse = /** status 200 Successful Response */ PreformTechMapResponse;
export type UpdatePreformTechMapApiArg = {
	preformTechMapId: number;
	xDisableCatalogNumRemainderCheck?: boolean;
	bodyUpdatePreformTechMap: BodyUpdatePreformTechMap;
};
export type DeletePreformTechMapApiResponse = /** status 200 Successful Response */ any;
export type DeletePreformTechMapApiArg = {
	preformTechMapId: number;
};
export type GetPreformTechMapByNumberApiResponse = /** status 200 Successful Response */ PreformTechMapResponse;
export type GetPreformTechMapByNumberApiArg = {
	preformTechMapNumber: number;
};
export type ChangePreformTechMapFileApiResponse = /** status 200 Successful Response */ PreformTechMapResponse;
export type ChangePreformTechMapFileApiArg = {
	preformTechMapId: number;
	bodyChangePreformTechMapFile: BodyChangePreformTechMapFile;
};
export type CancelPreformTechMapApiResponse = /** status 204 Successful Response */ void;
export type CancelPreformTechMapApiArg = {
	preformTechMapId: number;
};
export type GetAllCatalogNamesApiResponse = /** status 200 Successful Response */ CatalogNameListForAll;
export type GetAllCatalogNamesApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetAllCatalogSymbolsApiResponse = /** status 200 Successful Response */ CatalogSymbolListForAll;
export type GetAllCatalogSymbolsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetUniqueCustomNumsApiResponse = /** status 200 Successful Response */ NumUniqueList;
export type GetUniqueCustomNumsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetAllTechMapsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsTechMapTechMapTechMapForAll;
export type GetAllTechMapsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	group?: string[];
	name?: string;
	symbol?: string;
	numStrict?: number;
	statuses?: TechMapAllStatus[];
	wasted?: boolean;
	commented?: boolean;
	createdAtFrom?: string;
	createdAtTo?: string;
	orderBy?: TechMapAllSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type GetAllTechMapsExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetAllTechMapsExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	id?: number;
	number?: number;
	group?: string[];
	name?: string;
	symbol?: string;
	numStrict?: number;
	statuses?: TechMapAllStatus[];
	wasted?: boolean;
	commented?: boolean;
	createdAtFrom?: string;
	createdAtTo?: string;
	orderBy?: TechMapAllSortingFields;
	orderDir?: SortingDirection;
};
export type PostSeedDbApiResponse = /** status 200 Successful Response */ any;
export type PostSeedDbApiArg = void;
export type SeedDefaultProductsApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultProductsApiArg = void;
export type SeedDefaultNumApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultNumApiArg = void;
export type SeedDefaultEmployeeApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultEmployeeApiArg = void;
export type SeedUsersApiResponse = /** status 200 Successful Response */ any;
export type SeedUsersApiArg = void;
export type RefreshCurrentOperationApiResponse = /** status 200 Successful Response */ any;
export type RefreshCurrentOperationApiArg = void;
export type RefreshCurrentDetailsCountApiResponse = /** status 200 Successful Response */ any;
export type RefreshCurrentDetailsCountApiArg = void;
export type RefreshWastedApiResponse = /** status 200 Successful Response */ any;
export type RefreshWastedApiArg = void;
export type RefreshQrApiResponse = /** status 200 Successful Response */ any;
export type RefreshQrApiArg = void;
export type MaterialCatalogDomain = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type ValidationError = {
	loc: (string | number)[];
	msg: string;
	type: string;
};
export type HttpValidationError = {
	detail?: ValidationError[];
};
export type MaterialCatalogCreateRequest = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type OperationTypeCatalogDomain = {
	name?: string;
	id?: number;
};
export type OperationTypeCatalogCreate = {
	name: string;
};
export type PreformTechMapDomainForCanceledProductOperation = {
	id: number;
	number: number;
};
export type CanceledProductOperationResponse = {
	product_operation_id: number;
	canceled_by_preform_tech_map_id: number;
	id: number;
	canceled_by_preform_tech_map: PreformTechMapDomainForCanceledProductOperation;
};
export type ProductOperationResponse = {
	/** название операции */
	name?: string;
	product_tech_map_id: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
	id?: number;
	canceled_by?: CanceledProductOperationResponse;
};
export type ProductOperationUpdate = {
	id: number;
	step_id?: number | string;
	wasted?: number | string;
	count_in_gram?: number | string;
	count_in_number?: number | string;
	count_out_gram?: number | string;
	count_out_number?: number | string;
	date?: string | string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	/** название операции */
	name?: string;
	has_link?: boolean;
	is_canceled?: boolean;
};
export type DefaultProductOperationUpdateInOperation = {
	id: number;
	step_id?: number | string;
	wasted?: number | string;
	count_in_gram?: number | string;
	count_in_number?: number | string;
	count_out_gram?: number | string;
	count_out_number?: number | string;
	date?: string | string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
};
export type BodyUpdateOperations = {
	operations_payload: ProductOperationUpdate[];
	default_operations_payload: DefaultProductOperationUpdateInOperation[];
};
export type ProductOperationCreate = {
	/** название операции */
	name?: string;
	product_tech_map_id?: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
};
export type DefaultProductOperationResponse = {
	/** Название операции */
	name?: string;
	/** Порядковый номер операции */
	order_number: number;
	id?: number;
};
export type LinkedProductOperationsDirections = 'from' | 'to';
export type LinkedProductOperationsResponse = {
	id?: number;
	direction: LinkedProductOperationsDirections;
	/** Куда/откуда произошла связь с тех картой на деталь */
	product_tech_map_number?: number;
	/** ФИО ответственного */
	responsible_employee_fio?: string;
	date?: string | string;
	/** Количество переданных штук */
	count_number?: number;
	/** Количество переданных грамм */
	count_gram?: number;
};
export type BodyUpdateOperationsOperator = {
	operations_payload: ProductOperationUpdate[];
	default_operations_payload: DefaultProductOperationUpdateInOperation[];
};
export type CancelProductOperationRequest = {
	product_operation_id: number;
	closing_employee_fio: string;
};
export type RenewalProductOperationRequest = {
	product_operation_id: number;
};
export type ProductOperationTypesCatalog = {
	operation_type_id?: number | string;
	name?: string;
	step_id?: number;
};
export type FileResponse = {
	id: string;
	filename: string;
	size: number;
};
export type ProductCatalogResponse = {
	/** Обозначение детали */
	symbol: string;
	/** Наименование детали */
	name: string;
	/** КС/ИИ */
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	standard_consumption_meters?: string | number;
	standard_consumption_square_meters?: string | number;
	standard_consumption_grams?: string | number;
	standard_consumption_kg?: string | number;
	material_id?: number | MaterialCatalogDomain;
	id?: number;
	material?: MaterialCatalogDomain;
	operation_types?: ProductOperationTypesCatalog[];
	file_id?: string | string;
	file?: FileResponse;
};
export type ResponsePaginationApiListAppModelsProductProductCatalogContractsProductCatalogResponse = {
	payload: ProductCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type StandardConsumptionTypeEnum = 'meters' | 'square_meters' | 'grams' | 'kg' | 'old';
export type ProductCatalogSortingFields =
	| 'name'
	| 'symbol'
	| 'material_symbol'
	| 'material_gost'
	| 'material_sortament'
	| 'material_gost_na_sortament'
	| 'standard_consumption_meters'
	| 'standard_consumption_square_meters'
	| 'standard_consumption_kg'
	| 'standard_consumption_grams';
export type SortingDirection = 'asc' | 'desc';
export type FileDomain = {
	/** Path to a picture */
	path: string;
	/** Name of file that was uploaded */
	filename: string;
	/** Size of file in bytes */
	size: number;
	/** Name of bucket with this file */
	bucket_name: string;
	/** Type of file from Enum */
	file_type: string;
	id?: string;
};
export type ProductCatalogDomain = {
	/** Обозначение детали */
	symbol: string;
	/** Наименование детали */
	name: string;
	/** КС/ИИ */
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number | MaterialCatalogDomain;
	id?: number;
	material?: MaterialCatalogDomain;
	operation_types?: ProductOperationTypesCatalog[];
	file_id?: string | string;
	file?: FileDomain;
};
export type ProductCatalogUpdate = {
	symbol?: string;
	name?: string;
	approval_card?: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number;
	id: number;
};
export type OperationTypeCatalogCreateProductRequest = {
	name: string;
	id?: number;
	step_id?: number;
};
export type BodyUpdateProduct = {
	update_product_request: ProductCatalogUpdate;
	material: MaterialCatalogCreateRequest;
	product_operations: OperationTypeCatalogCreateProductRequest[];
};
export type ProductCatalogCreate = {
	symbol: string;
	name: string;
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number;
};
export type BodyCreateProduct = {
	product: ProductCatalogCreate;
	material: MaterialCatalogCreateRequest;
	product_operations: OperationTypeCatalogCreateProductRequest[];
};
export type ProductCatalogNameListResponse = {
	payload: string[];
};
export type ProductCatalogSymbolListResponse = {
	payload: string[];
};
export type BodyAddFileToDetail = {
	file: Blob;
};
export type ProductTechMapStatus =
	| 'progress'
	| 'accepted_at_sgd'
	| 'partially_issued'
	| 'released'
	| 'completed'
	| 'wasted';
export type ProductTechMapAdditionalStatusAdmin = 'canceled';
export type UserRoleEnum = 'user' | 'operator' | 'senior_operator' | 'admin' | 'executor' | 'accountant';
export type UserDomain = {
	id?: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	role?: UserRoleEnum;
	created_at?: string;
	updated_at?: string;
	last_login?: string;
	auth_count?: number;
	is_active: boolean;
	email?: string;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
	password_hash?: string;
	password_salt?: string;
	password?: string;
	reset_password_hash?: string;
	reset_password_hash_expired?: string;
};
export type CanceledProductOperation = {
	product_operation_id: number;
	canceled_by_preform_tech_map_id: number;
	id: number;
	canceled_by_preform_tech_map: PreformTechMapDomainForCanceledProductOperation;
};
export type ProductOperationDomain = {
	/** название операции */
	name?: string;
	product_tech_map_id: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
	id?: number;
	canceled_by?: CanceledProductOperation;
};
export type CustomMaterialDomain = {
	/** Идентификатор справочного материала */
	material_catalog_id?: number;
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type ProductCatalogForCustomProductResponse = {
	/** Обозначение детали */
	symbol: string;
	/** Наименование детали */
	name: string;
	/** КС/ИИ */
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	standard_consumption_meters?: string | number;
	standard_consumption_square_meters?: string | number;
	standard_consumption_grams?: string | number;
	standard_consumption_kg?: string | number;
	material_id?: number | MaterialCatalogDomain;
	id?: number;
	material?: MaterialCatalogDomain;
	operation_types: ProductOperationTypesCatalog[];
	file_id?: string | string;
	file?: FileResponse;
};
export type CustomProductResponse = {
	product_tech_map_id?: number;
	/** Идентификатор справочной детали */
	product_catalog_id?: number;
	material_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ поле для символа */
	approval_card?: string;
	/** КС/ИИ поле для экстра символа */
	extra_approval_card?: string;
	id?: number;
	material: CustomMaterialDomain;
	product_catalog?: ProductCatalogForCustomProductResponse;
};
export type AssemblyTechMapForProductSgdOut = {
	id: number;
	number: number;
};
export type ProductSgdOutDomain = {
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	receiver?: string;
	/** ФИО */
	fio?: string;
	id?: number;
	assembly_tech_map_receiver?: AssemblyTechMapForProductSgdOut;
};
export type ProductReleaseDomain = {
	/** Выдано, мм Х мм */
	count?: string;
	/** Выдано, кг */
	count_kg?: string;
	/** Партия (№МК) материала */
	number?: string;
	/** ФИО выдавшего мат. */
	fio?: string;
	id?: number;
	product_plan_id: number;
	/** Номер заготовительной тех карты, из которой пришёл материал */
	preform_tech_map_giver_number?: number;
};
export type ProductPlanResponse = {
	/** План кол-во дет., шт. */
	count: number;
	NR_meters?: string | number;
	NR_square_meters?: string | number;
	NR_kg?: string | number;
	NR_grams?: string | number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issuance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	id?: number;
	product_releases?: ProductReleaseDomain[];
	product_tech_map_id?: number;
};
export type OvOrDateOfManufactureDecision = 'o_v' | 'date_of_manufacture' | 'empty';
export type CustomNumDomain = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	id?: number;
	product_tech_map_id?: number;
};
export type ProductTechMapCommentDomain = {
	text: string;
	product_tech_map_id: number;
	user_id?: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserDomain;
};
export type ProductTechMapResponse = {
	/** Номер техкарты */
	number?: number;
	/** Группа, к которой относится техкарта (АА. АБ. и т.д.) */
	symbol?: string;
	/** Сумма всего брака */
	wasted?: number;
	status?: ProductTechMapStatus | ProductTechMapAdditionalStatusAdmin;
	/** QR для телефонов, куда зашита ссылка на техкарту */
	qr_code?: Blob;
	/** Дата создания техкарты */
	created_at?: string;
	updated_at?: string;
	id?: number;
	user_id?: number;
	user?: UserDomain;
	storage_at?: string;
	/** Количество деталей, что было передано на СГД */
	accepted_at_sgd_count?: number;
	/** Остаток деталей, что нужно выдать с СГД. Формула: accepted_at_sgd_count - (sum(Выдано из СГД на текущий момент)) */
	remaining_count?: number;
	current_details_count?: number;
	/** Количество комментариев к техкарте */
	comments_count?: number;
	/** Дефолтные операции, что есть у каждой техкарты на деталь */
	default_product_operations?: ProductOperationDomain[];
	product?: CustomProductResponse;
	/** Кастомные операции */
	product_operations?: ProductOperationDomain[];
	product_sgd_out?: ProductSgdOutDomain[];
	product_plan: ProductPlanResponse;
	/** НУМ */
	num?: CustomNumDomain;
	current_product_operation_id?: number;
	/** Операция, которая прямо сейчас находится в работе */
	current_product_operation?: ProductOperationDomain;
	comments?: ProductTechMapCommentDomain[];
	file_id?: string | string;
	file?: FileResponse;
};
export type ResponsePaginationApiListAppModelsProductProductTechMapContractsProductTechMapResponse = {
	payload: ProductTechMapResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type ProductTechMapsSortingFields =
	| 'number'
	| 'product_catalog.name'
	| 'product_catalog.symbol'
	| 'accepted_at_sgd_count'
	| 'wasted'
	| 'remaining_count'
	| 'status'
	| 'created_at'
	| 'updated_at'
	| 'storage_at';
export type ProductTechMapStatusForFilter =
	| 'progress'
	| 'accepted_at_sgd'
	| 'partially_issued'
	| 'released'
	| 'canceled'
	| 'completed'
	| 'wasted';
export type ProductTechMapUpdateRequest = {
	/** Номер техкарты */
	number?: number;
	/** Группа, к которой относится техкарта (АА. АБ. и т.д.) */
	symbol?: string;
	/** Сумма всего брака */
	wasted?: number;
	status?: ProductTechMapStatus | ProductTechMapAdditionalStatusAdmin;
	/** QR для телефонов, куда зашита ссылка на техкарту */
	qr_code?: Blob;
	/** Дата создания техкарты */
	created_at?: string;
	updated_at?: string;
	id: number;
};
export type CustomMaterialCreate = {
	/** Идентификатор справочного материала */
	material_catalog_id: number;
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
};
export type CustomProductRequestCreate = {
	/** Идентификатор справочной детали */
	product_catalog_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ для символа */
	approval_card?: string;
	/** КС/ИИ для экстра символа */
	extra_approval_card?: string;
	material: CustomMaterialCreate;
};
export type ProductOperationRequestCreate = {
	/** название операции */
	name?: string;
	step_id: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
};
export type CustomNumCreateRequest = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
};
export type ProductPlanCreateInTechMapRequest = {
	fio?: string;
	issuance_count?: number;
	list_number?: number;
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, м */
	NR_meters?: number | string;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number | string;
	/** Норма расхода на ед, кг */
	NR_kg?: number | string;
	/** Норма расхода на ед, грамм */
	NR_grams?: number | string;
};
export type ProductTechMapRequestCreate = {
	wasted?: number;
	symbol?: string;
	product: CustomProductRequestCreate;
	product_operations: ProductOperationRequestCreate[];
	num: CustomNumCreateRequest;
	product_plan: ProductPlanCreateInTechMapRequest;
	/** Дата создания техкарты */
	created_at?: string;
	/** Field need to add id from product_catalog.file_id */
	file_id?: string;
};
export type NumUniqueList = {
	/** Список номеров кастомных НУМ */
	payload?: number[];
};
export type ProductTechMapTreeItemResponse = {
	id?: number;
	number?: number;
};
export type LinkedProductOperationsItem = {
	id: number;
	from_product_tech_map: ProductTechMapTreeItemResponse;
	to_product_tech_map: ProductTechMapTreeItemResponse;
};
export type ProductTechMapChangeStatusRequest = {
	product_tech_map_id: number;
	status: ProductTechMapStatus;
};
export type ProductTechMapLinkedRequestWithNewCreate = {
	parent_product_tech_map_id: number;
	children_product_catalog_id: number;
	children_operation_type_id?: number;
	children_operation_step_id: number;
	children_default_operation_id?: number;
	plan_count: number;
	fio: string;
	responsible_employee_fio: string;
	from_parent_created_at: string;
	count_gram?: number;
	count_number: number;
	/** Field need to add id from product_catalog.file_id */
	file_id?: string | string;
};
export type ProductTechMapLinkedRequestWithExistedCreate = {
	parent_product_tech_map_id: number;
	children_product_tech_map_id: number;
	responsible_employee_fio: string;
	from_parent_created_at: string;
	count_gram?: number;
	count_number: number;
};
export type AvailableUpdateLinkBoundariesResponse = {
	count_gram_min: number;
	count_gram_max: number;
	count_number_min: number;
	count_number_max: number;
};
export type ProductTechMapLinkUpdateRequest = {
	id: number;
	responsible_employee_fio?: string;
	from_parent_created_at?: string;
	count_gram?: number;
	count_number?: number;
};
export type BodyChangeProductTechMapFile = {
	file: Blob;
};
export type BodyCreateTechMapPrintPdf = {
	file: Blob;
};
export type UserResponse = {
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	is_active?: boolean;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type ProductTechMapCommentForUserResponse = {
	text: string;
	product_tech_map_id: number;
	user_id: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserResponse;
	able_edit?: boolean;
};
export type ProductTechMapCommentCreateRequest = {
	text: string;
};
export type ProductTechMapCommentUpdateTextRequest = {
	text: string;
};
export type StorageLogDomain = {
	user_id: number;
	product_id: number;
	product_tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
	id?: number;
	created_at?: string;
};
export type StorageLogUpdate = {
	user_id: number;
	product_id: number;
	product_tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
	id: number;
};
export type StorageLogCreate = {
	user_id: number;
	product_id: number;
	product_tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
};
export type UserBase = {
	id: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	role?: UserRoleEnum;
	created_at?: string;
	updated_at?: string;
	last_login?: string;
	auth_count?: number;
	is_active: boolean;
	email?: string;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type UserUpdateRequest = {
	id?: number;
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	email?: string;
	is_active?: boolean;
	role?: UserRoleEnum;
	date_of_dismissal?: string | string;
};
export type UserUpdatePasswordRequest = {
	old_password: string;
	new_password: string;
	new_password_repeat: string;
};
export type Token = {
	access_token: string;
	expires: string;
	refresh_token: string;
	token_type?: string;
	user_role: UserRoleEnum;
};
export type BodyAuth = {
	grant_type?: string;
	username: string;
	password: string;
	scope?: string;
	client_id?: string;
	client_secret?: string;
};
export type RefreshToken = {
	refresh_token: string;
};
export type SupplierCatalogResponse = {
	name?: string;
	id: number;
};
export type ManufacturerCatalogResponse = {
	name?: string;
	id: number;
};
export type InvoiceResponse = {
	number: string;
	/** Дата поставки */
	date: string;
	id: number;
};
export type NumCatalogResponse = {
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
	/** Дата поставки */
	date_of_delivery?: string;
	/** остаток материала */
	remainder: number;
	id: number;
	supplier?: SupplierCatalogResponse;
	manufacturer?: ManufacturerCatalogResponse;
	invoice?: InvoiceResponse;
};
export type ResponsePaginationApiListAppModelsNumCatalogContractsNumCatalogResponse = {
	payload: NumCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type NumCatalogSortingFields =
	| 'num'
	| 'symbol'
	| 'gost'
	| 'sortament'
	| 'gost_na_sortament'
	| 'number_of_melt'
	| 'o_v'
	| 'certificate'
	| 'batch_number'
	| 'divisions'
	| 'valid_until'
	| 'validity'
	| 'comment'
	| 'n_b'
	| 'standard_consumption_type'
	| 'quantity'
	| 'remainder'
	| 'date_of_delivery'
	| 'supplier_catalog_name'
	| 'manufacturer_catalog_name'
	| 'invoice_number';
export type SupplierCatalogCreateRequest = {
	name?: string;
	id?: number;
};
export type ManufacturerCatalogCreateRequest = {
	name?: string;
	id?: number;
};
export type InvoiceUpdateRequest = {
	number: string;
	/** Дата поставки */
	date: string;
	id?: number;
};
export type NumCatalogUpdateRequest = {
	supplier?: SupplierCatalogCreateRequest;
	manufacturer?: ManufacturerCatalogCreateRequest;
	invoice: InvoiceUpdateRequest;
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	standard_consumption_type?: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
	/** Дата поставки */
	date_of_delivery?: string;
	/** Обязательное поле для модели на обновление. */
	id: number;
};
export type InvoiceCreateRequest = {
	number: string;
	/** Дата поставки */
	date: string;
	id?: number;
};
export type NumCatalogCreateRequest = {
	supplier?: SupplierCatalogCreateRequest;
	manufacturer?: ManufacturerCatalogCreateRequest;
	invoice: InvoiceCreateRequest;
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
	date_of_delivery: string;
};
export type ProductSgdOutUpdateRequest = {
	/** Обязательное поля для обновления сущности */
	id: number;
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	receiver?: string;
	/** ФИО */
	fio?: string;
	/** Идентификатор техкарты на деталь, к которой привязан отчет по СГД */
	product_tech_map_id: number;
	/** Идентификатор сборочной техкарты, в которую переданы детали */
	assembly_tech_map_receiver_id?: number;
};
export type ProductSgdOutCreateRequest = {
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	receiver?: string;
	/** ФИО */
	fio?: string;
	/** Идентификатор техкарты на деталь, к которой привязан отчет по СГД */
	product_tech_map_id: number;
	/** Идентификатор сборочной техкарты, в которую переданы детали */
	assembly_tech_map_receiver_id?: number;
};
export type BodyPutSgdOutOperator = {
	update_payload: ProductSgdOutUpdateRequest[];
	create_payload: ProductSgdOutCreateRequest[];
};
export type ProductPlanDomain = {
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, метр */
	NR_meters?: number;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number;
	/** Норма расхода на ед, кг */
	NR_kg?: number;
	/** Норма расхода на ед, грамм */
	NR_grams?: number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issuance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	id?: number;
	product_releases?: ProductReleaseDomain[];
	product_tech_map_id?: number;
};
export type ProductReleaseUpdateRequest = {
	/** Выдано, мм Х мм */
	count?: string;
	/** Выдано, кг */
	count_kg?: string;
	/** Партия (№МК) материала */
	number?: string;
	/** ФИО выдавшего мат. */
	fio?: string;
	/** Обязательное поле, т.к. мы должны знать что обновить */
	id?: number;
	/** Foreign key to product_plan */
	product_plan_id?: number;
};
export type ProductPlanUpdateOperatorRequest = {
	/** Поле обязательное так как мы должны знать какую модель обновить */
	id: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое решение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	/** Номер НУМа, который был ДО обновления техкарты. В рамках решения AGAT-1772 значение старого НУМа, необходимо для корректного обновления остатка в текущем (новом) НУМе. Если НУМ не менялся, допускается None значение */
	old_num?: number;
	product_releases?: ProductReleaseUpdateRequest[];
};
export type CustomMaterialResponse = {
	/** Идентификатор справочного материала */
	material_catalog_id?: number;
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type CustomMaterialRequestUpdate = {
	id: number;
	/** Идентификатор справочного материала */
	material_catalog_id?: number;
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
};
export type CustomProductRequestUpdate = {
	id: number;
	product_tech_map_id?: number;
	/** Идентификатор справочной детали */
	product_catalog_id?: number;
	material_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ поле для символа */
	approval_card?: string;
	/** КС/ИИ поле для экстра символа */
	extra_approval_card?: string;
};
export type CustomNumUpdateRequest = {
	id: number;
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Норма расхода на ед, метр */
	product_plan_NR_meters?: number;
	/** Норма расхода на ед, метр^2 */
	product_plan_NR_square_meters?: number;
	/** Норма расхода на ед, кг */
	product_plan_NR_kg?: number;
	/** Норма расхода на ед, грамм */
	product_plan_NR_grams?: number;
};
export type EmployeeCatalogResponse = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
	id?: number;
};
export type EmployeeCatalogInitialsResponse = {
	/** ID of employee */
	id: number;
	/** Processed initials of employee */
	initials: string;
};
export type TechMapSymbolCatalogDomain = {
	symbol?: string;
	id?: number;
};
export type MaterialCatalogUpdate = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id: number;
};
export type OperationTypeCatalogUpdate = {
	name?: string;
	id: number;
};
export type ProductPlanUpdateRequest = {
	/** Поле обязательное так как мы должны знать какую модель обновить */
	id: number;
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, метр */
	NR_meters?: number;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number;
	/** Норма расхода на ед, кг */
	NR_kg?: number;
	/** Норма расхода на ед, грамм */
	NR_grams?: number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issuance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	product_releases?: ProductReleaseUpdateRequest[];
	/** Номер НУМа, который был ДО обновления техкарты. В рамках решения AGAT-1772 значение старого НУМа, необходимо для корректного обновления остатка в текущем (новом) НУМе. Если НУМ не менялся, допускается None значение */
	old_num?: number;
};
export type ProductTechMapChangeStatusAdminRequest = {
	product_tech_map_id: number;
	status: ProductTechMapAdditionalStatusAdmin;
};
export type UserResponseAdmin = {
	id: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	email?: string;
	role: UserRoleEnum;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type ResponsePaginationApiListAppModelsUserContractsUserResponseAdmin = {
	payload: UserResponseAdmin[];
	pages?: number;
	page?: number;
	total?: number;
};
export type UserSortingFields = 'first_name' | 'last_name' | 'middle_name' | 'role' | 'email';
export type UserRoleForAdminCreateEnum = 'user' | 'operator' | 'senior_operator' | 'accountant';
export type UserUpdateAdminRequest = {
	id: number;
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	email?: string;
	role?: UserRoleForAdminCreateEnum;
	password?: string;
	date_of_work_start?: string;
	position?: string;
};
export type UserCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	username: string;
	email: string;
	password: string;
	role: UserRoleForAdminCreateEnum;
	position?: string;
	date_of_work_start?: string;
};
export type UserExecutorCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position?: string;
	date_of_work_start?: string;
};
export type UserFireRequest = {
	user_id: number;
	date_of_dismissal?: string;
};
export type UserRestoreRequest = {
	user_id: number;
};
export type UserActivateRequest = {
	user_id: number;
	password: string;
	email: string;
	role: UserRoleEnum;
};
export type EmployeeCatalogUpdateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
	id: number;
};
export type EmployeeCatalogCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
};
export type IssueReportTypeEnum = 'stuck_operation' | 'wasted_limit';
export type IssueReportStatusEnum = 'open' | 'progress' | 'closed';
export type IssueCommentResponse = {
	text: string;
	issue_report_id: number;
	user_id: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserResponse;
};
export type IssueReportResponse = {
	type: IssueReportTypeEnum;
	status: IssueReportStatusEnum;
	/** Дата создания проблемы */
	open_at: string;
	closed_at?: string;
	stuck_operation_at?: string;
	/** ТехКарта в которой возникла проблема */
	product_tech_map_id?: number;
	/** Операция, из-за которой появилась проблема.Выбирается пользователем при закрытии проблемы. */
	problem_product_operation_id?: number;
	/** Пользователь, который взял проблему в исполнение. Назначается автоматически тому юзеру, который перевел задачу в статус 'В работе' */
	executor_user_id?: number;
	/** Пользователь, который назначается исполнителем при переводе задачи из статуса 'В работе' в 'Завершено' */
	responsible_user_id?: number;
	created_at?: string;
	updated_at?: string;
	id: number;
	product_tech_map?: ProductTechMapResponse;
	problem_product_operation?: ProductOperationDomain;
	executor_user?: UserResponse;
	responsible_user?: UserResponse;
	comments: IssueCommentResponse[];
};
export type ResponsePaginationApiListAppModelsIssueIssueReportContractsIssueReportResponse = {
	payload: IssueReportResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type IssueReportSortingFields =
	| 'open_at'
	| 'status'
	| 'executor_user'
	| 'responsible_user'
	| 'operation_name'
	| 'product_name'
	| 'stuck_operation_at';
export type IssueStuckOperationResponse = {
	/** Операция, которая зависла (больше скольки-то дней не меняется. Назначается автоматически celery, может не являться истинной проблемой, поэтому в главной сущности есть специальной поле для реально проблемной операции. */
	stuck_product_operation_id: number;
	issue_report_id: number;
	/** Дата, когда эта начались работе по операции */
	date: string;
	/** Дата создания проблемы */
	created_at?: string;
	updated_at?: string;
	id: number;
	stuck_product_operation: ProductOperationDomain;
	issue_report: IssueReportResponse;
};
export type IssueCommentCreateRequest = {
	text: string;
};
export type IssueReportUpdateStatusToClosedRequest = {
	responsible_user_id: number;
	comment: string;
	problem_product_operation_id: number;
};
export type NotificationTypeEnum = 'stuck_operation' | 'wasted_limit';
export type NewIssueNotificationResponse = {
	product_tech_map_id: number;
	product_tech_map_number: number;
	tech_map_symbol?: string;
	stuck_product_operation_id: number;
	stuck_operation_name: string;
	stuck_operation_step_id: number;
	issue_report_id: number;
};
export type NotificationResponse = {
	/** Тип нотификации */
	type: NotificationTypeEnum;
	data: NewIssueNotificationResponse;
	/** Дата создания проблемы */
	created_at?: string;
	id: number;
	is_read?: boolean;
};
export type ResponsePaginationApiListAppModelsSupplierCatalogContractsSupplierCatalogResponse = {
	payload: SupplierCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type SupplierCatalogSortingFields = 'name';
export type ResponsePaginationApiListAppModelsManufacturerCatalogContractsManufacturerCatalogResponse = {
	payload: ManufacturerCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type ManufacturerCatalogSortingFields = 'name';
export type ResponsePaginationApiListAppModelsInvoiceContractsInvoiceResponse = {
	payload: InvoiceResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type AssemblyCatalogProductResponse = {
	product_catalog_id?: number;
	product_symbol: string;
	product_name: string;
	assembly_id?: number;
};
export type AssemblyOperationTypesCatalogResponse = {
	id: number;
	operation_type_id?: number;
	assembly_id?: number;
	step_id?: number;
	name?: string;
};
export type AssemblyCatalogResponse = {
	/** Обозначение изделия */
	symbol: string;
	/** Наименование изделия */
	name: string;
	id: number;
	products?: AssemblyCatalogProductResponse[];
	operation_types?: AssemblyOperationTypesCatalogResponse[];
	file_id?: string | string;
	file?: FileDomain;
};
export type ResponsePaginationApiListAppModelsAssemblyAssemblyCatalogAssemblyCatalogContractsAssemblyCatalogResponse = {
	payload: AssemblyCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type AssemblyCatalogSortingFields = 'symbol' | 'name';
export type AssemblyCatalogProductCreateRequest = {
	product_catalog_id?: number;
	product_symbol: string;
	product_name: string;
};
export type AssemblyOperationTypesCatalogCreateRequest = {
	name?: string;
	step_id?: number;
};
export type AssemblyCatalogCreateRequest = {
	/** Обозначение изделия */
	symbol: string;
	/** Наименование изделия */
	name: string;
	products?: AssemblyCatalogProductCreateRequest[];
	operation_types: AssemblyOperationTypesCatalogCreateRequest[];
};
export type AssemblyCatalogProductUpdateRequest = {
	product_catalog_id?: number;
	product_symbol: string;
	product_name: string;
};
export type AssemblyOperationTypesCatalogUpdateRequest = {
	name?: string;
	step_id?: number;
};
export type AssemblyCatalogUpdateRequest = {
	symbol?: string;
	name?: string;
	products?: AssemblyCatalogProductUpdateRequest[];
	operation_types?: AssemblyOperationTypesCatalogUpdateRequest[];
};
export type BodyAddFileToAssembly = {
	file: Blob;
};
export type AssemblyTechMapStatus = 'progress' | 'accepted_at_sgd' | 'partially_issued' | 'released' | 'canceled';
export type CustomAssemblyResponse = {
	/** Обозначение изделия */
	symbol: string;
	/** Наименование изделия */
	name: string;
	id: number;
	assembly_tech_map_id: number;
};
export type ProductTechMapDomainForAssemblyTechMap = {
	id?: number;
	number?: number;
	symbol?: string;
	storage_at?: string;
	num?: CustomNumDomain;
};
export type ProductSgdOutDomainForAssemblyTechMap = {
	id?: number;
	count?: number;
	date?: string;
};
export type AssemblyTechMapProductTechMapResponse = {
	assembly_tech_map_id: number;
	product_tech_map_id?: number;
	product_sgd_out_id?: number;
	product_catalog_id?: number;
	product_symbol: string;
	product_name: string;
	size?: string;
	id: number;
	product_catalog?: ProductCatalogResponse;
	product_tech_map?: ProductTechMapDomainForAssemblyTechMap;
	product_sgd_out?: ProductSgdOutDomainForAssemblyTechMap;
};
export type AssemblyOperationResponse = {
	step_id: number;
	name: string;
	fio?: string;
	date?: string;
	count_in_number?: number;
	count_out_number?: number;
	wasted?: number;
	id: number;
	assembly_tech_map_id: number;
	user_id?: number;
	is_canceled?: boolean;
	created_at?: string;
	is_default?: boolean;
};
export type AssemblySgdOutResponse = {
	date?: string;
	count?: number;
	receiver?: string;
	certificate?: string;
	fio?: string;
	id: number;
	assembly_tech_map_id: number;
};
export type AssemblyTechMapCommentDomain = {
	text: string;
	assembly_tech_map_id: number;
	user_id?: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserDomain;
};
export type AssemblyTechMapResponse = {
	status?: AssemblyTechMapStatus;
	/** Номер техкарты */
	number?: number;
	/** Сумма всего брака */
	wasted?: number;
	/** Количество изделий, планируемое произвести */
	plan?: number;
	/** Номер карты согласования */
	approval_card?: string;
	/** Номер карты отклонения */
	tech_decision?: string;
	/** Номер карты отклонения */
	fio_pds?: string;
	/** Дата создания техкарты */
	created_at?: string;
	/** Дата обновления техкарты */
	updated_at?: string;
	id: number;
	user_id?: number;
	current_assembly_operation_id?: number;
	assembly_tech_map_symbol_id?: number;
	file_id?: string | string;
	/** Группа, к которой относится техкарта */
	symbol?: string;
	/** Количество деталей, что было передано на СГД */
	accepted_at_sgd_count?: number;
	/** Остаток деталей, что нужно выдать с СГД. Формула: accepted_at_sgd_count - (sum(Выдано из СГД на текущий момент)) */
	remaining_count?: number;
	/** Количество деталей на текущий момент */
	current_details_count?: number;
	/** Количество комментариев к техкарте */
	comments_count?: number;
	/** Дата сдачи техкарты (дата последней дефолтной операции) */
	storage_at?: string;
	/** Изделие */
	assembly?: CustomAssemblyResponse;
	/** Исходные детали, техкарты */
	assembly_products: AssemblyTechMapProductTechMapResponse[];
	/** Сборочные операции (кастомные и дефолтные) */
	assembly_operations?: AssemblyOperationResponse[];
	/** Операция, которая прямо сейчас находится в работе */
	current_assembly_operation?: AssemblyOperationResponse;
	/** Выдача из СГД */
	assembly_sgd_out?: AssemblySgdOutResponse[];
	comments?: AssemblyTechMapCommentDomain[];
	/** Пользователь, создавший техкарту */
	user?: UserResponse;
	file?: FileDomain;
};
export type ResponsePaginationApiListAppModelsAssemblyAssemblyTechMapAssemblyTechMapContractsAssemblyTechMapResponse = {
	payload: AssemblyTechMapResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type AssemblyTechMapsSortingFields =
	| 'number'
	| 'assembly_catalog.name'
	| 'assembly_catalog.symbol'
	| 'accepted_at_sgd_count'
	| 'wasted'
	| 'remaining_count'
	| 'status'
	| 'created_at'
	| 'updated_at'
	| 'storage_at';
export type CustomAssemblyCreateRequest = {
	/** Обозначение изделия */
	symbol: string;
	/** Наименование изделия */
	name: string;
};
export type ProductTechMapLazyRequestCreate = {
	wasted?: number;
	symbol?: string;
	product_catalog_id: number;
	num: number;
	product_plan: ProductPlanCreateInTechMapRequest;
	/** Дата создания техкарты */
	created_at?: string;
	/** Field need to add id from product_catalog.file_id */
	file_id?: string;
};
export type AssemblyTechMapProductTechMapCreateRequest = {
	product_catalog_id?: number;
	product_symbol: string;
	product_name: string;
	size?: string;
	product_tech_map_id?: number;
	product_tech_map_new?: ProductTechMapLazyRequestCreate;
	x_disable_catalog_num_remainder_check?: boolean;
};
export type AssemblyOperationCreateRequest = {
	step_id: number;
	name: string;
	fio?: string;
	date?: string;
	count_in_number?: number;
	count_out_number?: number;
	wasted?: number;
};
export type AssemblyTechMapCreateRequest = {
	wasted?: number;
	plan?: number;
	symbol?: string;
	assembly: CustomAssemblyCreateRequest;
	assembly_products: AssemblyTechMapProductTechMapCreateRequest[];
	assembly_operations: AssemblyOperationCreateRequest[];
	/** Номер карты согласования */
	approval_card?: string;
	/** Техническое решение по сборочной техкарте */
	tech_decision?: string;
	/** ФИО ПДС */
	fio_pds?: string;
	/** Дата создания сборочной техкарты */
	created_at?: string;
	/** Поле для создания копии файла из assembly_catalog.file_id */
	file_id?: string;
};
export type AssemblyTechMapUpdateRequest = {
	/** Номер техкарты */
	number?: number;
	symbol?: string;
	plan?: number;
	/** Номер карты согласования */
	approval_card?: string;
	/** Техническое решение по сборочной техкарте */
	tech_decision?: string;
	/** ФИО ПДС */
	fio_pds?: string;
};
export type AssemblyOperationUpdateRequest = {
	id: number;
	step_id: number;
	/** Название операции */
	name?: string;
	fio?: string;
	date?: string | string;
	count_in_number?: number;
	count_out_number?: number;
	wasted?: number;
	is_canceled?: boolean;
};
export type AssemblyOperationsUpdateOrCreateRequest = {
	assembly_operations_to_update?: AssemblyOperationUpdateRequest[];
	assembly_operations_to_create?: AssemblyOperationCreateRequest[];
};
export type AssemblySgdOutUpdateRequest = {
	date?: string;
	count?: number;
	receiver?: string;
	certificate?: string;
	fio?: string;
	id: number;
};
export type AssemblySgdOutCreateRequest = {
	date?: string;
	count?: number;
	receiver?: string;
	certificate?: string;
	fio?: string;
};
export type AssemblySgdOutsUpdateOrCreateRequest = {
	assembly_sgd_out_to_update?: AssemblySgdOutUpdateRequest[];
	assembly_sgd_out_to_create?: AssemblySgdOutCreateRequest[];
};
export type AssemblyTechMapProductTechMapUpdateRequest = {
	size?: string;
	id: number;
	product_tech_map_id?: number;
	count: number;
	date?: string;
};
export type AssemblyTechMapProductTechMapLinkWithNewTechMapRequest = {
	size?: string;
	id: number;
	count: number;
	date?: string;
	product_tech_map: ProductTechMapLazyRequestCreate;
	x_disable_catalog_num_remainder_check?: boolean;
};
export type AssemblyTechMapProductTechMapUpdateOrLinkRequest = {
	assembly_tech_map_products_to_update?: AssemblyTechMapProductTechMapUpdateRequest[];
	assembly_tech_map_products_to_link_with_new_tech_map?: AssemblyTechMapProductTechMapLinkWithNewTechMapRequest[];
};
export type BodyUpdateAssemblyTechMap = {
	assembly_tech_map_payload?: AssemblyTechMapUpdateRequest;
	assembly_operations_payload?: AssemblyOperationsUpdateOrCreateRequest;
	assembly_sgd_out_payload?: AssemblySgdOutsUpdateOrCreateRequest;
	assembly_tech_map_products_payload?: AssemblyTechMapProductTechMapUpdateOrLinkRequest;
};
export type AssemblyTechMapCommentForUserResponse = {
	text: string;
	assembly_tech_map_id: number;
	user_id: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserResponse;
	able_edit?: boolean;
};
export type AssemblyTechMapCommentCreateRequest = {
	text: string;
};
export type AssemblyTechMapCommentUpdateTextRequest = {
	text: string;
};
export type BodyChangeAssemblyTechMapFile = {
	file: Blob;
};
export type PreformOperationTypesCatalogResponse = {
	id: number;
	operation_type_id?: number;
	preform_id?: number;
	step_id?: number;
	name?: string;
};
export type PreformCatalogResponse = {
	/** Наименование процесса заготовки */
	name: string;
	id: number;
	operation_types?: PreformOperationTypesCatalogResponse[];
	file_id?: string | string;
	file?: FileDomain;
};
export type ResponsePaginationApiListAppModelsPreformPreformCatalogPreformCatalogContractsPreformCatalogResponse = {
	payload: PreformCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type PreformCatalogSortingFields = 'name';
export type PreformOperationTypesCatalogCreateRequest = {
	name?: string;
	step_id?: number;
};
export type PreformCatalogCreateRequest = {
	/** Наименование процесса заготовки */
	name: string;
	operation_types: PreformOperationTypesCatalogCreateRequest[];
};
export type PreformOperationTypesCatalogUpdateRequest = {
	name?: string;
	step_id?: number;
};
export type PreformCatalogUpdateRequest = {
	name?: string;
	operation_types?: PreformOperationTypesCatalogUpdateRequest[];
};
export type BodyAddFileToPreform = {
	file: Blob;
};
export type PreformTechMapStatus = 'progress' | 'accepted_at_storage' | 'partially_issued' | 'released' | 'canceled';
export type CustomPreformResponse = {
	/** Наименование процесса заготовки */
	name: string;
	id: number;
	preform_tech_map_id: number;
};
export type PreformPlanResponse = {
	/** Выдано, м Х шт. */
	issuance_count?: string;
	/** ФИО выдавшего материал */
	issuance_fio?: string;
	/** Возврат, м Х шт. */
	return_count?: string;
	/** ФИО принявшего материал */
	return_fio?: string;
	id: number;
	preform_tech_map_id: number;
};
export type PreformCustomNumResponse = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	/** Дата изготовления */
	date_of_manufacture?: string;
	/** Поле отвечает за то, что выводить пользователю в поле o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единицы нормы расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	id?: number;
	preform_tech_map_id?: number;
};
export type PreformOperationResponse = {
	step_id: number;
	name: string;
	fio?: string;
	date?: string;
	count_in_number?: string;
	count_out_number?: string;
	wasted?: string;
	id: number;
	preform_tech_map_id: number;
	user_id?: number;
	is_canceled?: boolean;
	created_at?: string;
};
export type ProductTechMapDomainForPreformTechMap = {
	id: number;
	number: number;
};
export type PreformSgdOutResponse = {
	product_tech_map_receiver_id: number;
	product_release_receiver_id: number;
	date?: string;
	count?: string;
	certificate?: string;
	fio_receiver?: string;
	fio_sender?: string;
	return_count?: string;
	return_date?: string;
	fio_receiver_returned?: string;
	id: number;
	preform_tech_map_id: number;
	product_tech_map_receiver: ProductTechMapDomainForPreformTechMap;
};
export type PreformTechMapResponse = {
	status?: PreformTechMapStatus;
	/** Номер техкарты */
	number?: number;
	/** Сумма всего брака */
	wasted?: number;
	/** Диаметр отпечатка */
	d_otp?: string;
	/** Номер карты отклонения */
	fio_pds?: string;
	/** Дата создания техкарты */
	created_at?: string;
	/** Дата обновления техкарты */
	updated_at?: string;
	id: number;
	user_id?: number;
	current_preform_operation_id?: number;
	file_id?: string | string;
	/** Количество деталей, что было передано на СГД */
	accepted_at_sgd_count?: number;
	/** Остаток деталей, что нужно выдать с СГД. Формула: accepted_at_sgd_count - (sum(Выдано из СГД на текущий момент)) */
	remaining_count?: number;
	/** Количество деталей на текущий момент */
	current_details_count?: number;
	/** Дата сдачи техкарты (дата последней дефолтной операции) */
	storage_at?: string;
	/** Кастомный заготовительный процесс */
	preform?: CustomPreformResponse;
	/** План заготовочной техкарты */
	preform_plan?: PreformPlanResponse;
	/** Кастомный НУМ */
	preform_num?: PreformCustomNumResponse;
	/** Сборочные операции (кастомные и дефолтные) */
	preform_operations?: PreformOperationResponse[];
	/** Операция, которая прямо сейчас находится в работе */
	current_preform_operation?: PreformOperationResponse;
	/** Выдача из СГД */
	preform_sgd_out?: PreformSgdOutResponse[];
	/** Пользователь, создавший техкарту */
	user?: UserResponse;
	file?: FileDomain;
	preform_tech_map_symbol_id?: number;
};
export type ResponsePaginationApiListAppModelsPreformPreformTechMapContractsPreformTechMapResponse = {
	payload: PreformTechMapResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type PreformTechMapsSortingFields =
	| 'number'
	| 'preform_catalog.name'
	| 'num'
	| 'num_symbol'
	| 'num_gost'
	| 'num_sortament'
	| 'num_gost_sortament'
	| 'accepted_at_sgd_count'
	| 'wasted'
	| 'current_details_count'
	| 'remaining_count'
	| 'return_count'
	| 'status'
	| 'created_at'
	| 'updated_at'
	| 'storage_at';
export type CustomPreformCreateRequest = {
	/** Наименование процесса заготовки */
	name: string;
};
export type PreformPlanCreateRequest = {
	/** Выдано, м Х шт. */
	issuance_count?: string;
	/** ФИО выдавшего материал */
	issuance_fio?: string;
	/** Возврат, м Х шт. */
	return_count?: string;
	/** ФИО принявшего материал */
	return_fio?: string;
};
export type PreformCustomNumCreateRequest = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	/** Дата изготовления */
	date_of_manufacture?: string;
	/** Поле отвечает за то, что выводить пользователю в поле o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единицы нормы расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
};
export type PreformOperationCreateRequest = {
	step_id: number;
	name: string;
	fio?: string;
	date?: string;
	count_in_number?: string;
	count_out_number?: string;
	wasted?: string;
};
export type PreformTechMapCreateRequest = {
	wasted?: number;
	/** Диаметр отпечатка */
	d_otp?: string;
	/** ФИО ПДС */
	fio_pds: string;
	/** Дата создания сборочной техкарты */
	created_at?: string;
	/** Поле для создания копии файла из preform_catalog.file_id */
	file_id?: string;
	preform: CustomPreformCreateRequest;
	preform_plan?: PreformPlanCreateRequest;
	preform_num: PreformCustomNumCreateRequest;
	preform_operations: PreformOperationCreateRequest[];
};
export type PreformTechMapUpdateRequest = {
	/** Номер техкарты */
	number?: number;
	/** Диаметр отпечатка */
	d_otp?: string;
	/** ФИО ПДС */
	fio_pds?: string;
};
export type CustomPreformUpdateRequest = {
	/** Наименование процесса заготовки */
	name: string;
	id: number;
};
export type PreformCustomNumUpdateRequest = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	/** Дата изготовления */
	date_of_manufacture?: string;
	/** Поле отвечает за то, что выводить пользователю в поле o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	id: number;
};
export type PreformPlanUpdateRequest = {
	/** Выдано, м Х шт. */
	issuance_count?: string;
	/** ФИО выдавшего материал */
	issuance_fio?: string;
	/** Возврат, м Х шт. */
	return_count?: string;
	/** ФИО принявшего материал */
	return_fio?: string;
	id: number;
};
export type PreformOperationUpdateRequest = {
	id: number;
	step_id: number;
	/** Название операции */
	name?: string;
	fio?: string;
	date?: string | string;
	count_in_number?: string;
	count_out_number?: string;
	wasted?: string;
	is_canceled?: boolean;
};
export type PreformOperationsUpdateOrCreateRequest = {
	preform_operations_to_update?: PreformOperationUpdateRequest[];
	preform_operations_to_create?: PreformOperationCreateRequest[];
};
export type PreformSgdOutUpdateRequest = {
	id: number;
	date?: string;
	count?: string;
	certificate?: string;
	fio_receiver?: string;
	fio_sender?: string;
	return_count?: string;
	return_date?: string;
	fio_receiver_returned?: string;
	product_tech_map_receiver_id: number;
};
export type PreformSgdOutCreateRequest = {
	date?: string;
	count?: string;
	certificate?: string;
	fio_receiver?: string;
	fio_sender?: string;
	return_count?: string;
	return_date?: string;
	fio_receiver_returned?: string;
	product_tech_map_receiver_id: number;
};
export type PreformSgdOutsUpdateOrCreateRequest = {
	preform_sgd_out_to_update?: PreformSgdOutUpdateRequest[];
	preform_sgd_out_to_create?: PreformSgdOutCreateRequest[];
};
export type BodyUpdatePreformTechMap = {
	preform_tech_map_payload?: PreformTechMapUpdateRequest;
	preform_payload?: CustomPreformUpdateRequest;
	preform_num_payload?: PreformCustomNumUpdateRequest;
	preform_plan_payload?: PreformPlanUpdateRequest;
	preform_operations_payload?: PreformOperationsUpdateOrCreateRequest;
	preform_sgd_out_payload?: PreformSgdOutsUpdateOrCreateRequest;
};
export type BodyChangePreformTechMapFile = {
	file: Blob;
};
export type CatalogNameListForAll = {
	payload: string[];
};
export type CatalogSymbolListForAll = {
	payload: string[];
};
export type TechMapAllStatus =
	| 'progress'
	| 'accepted_at_sgd'
	| 'accepted_at_storage'
	| 'partially_issued'
	| 'released'
	| 'completed'
	| 'canceled'
	| 'wasted';
export type TechMapForAll = {
	id: number;
	group?: string;
	number: number;
	type: string;
	symbol?: string;
	name: string;
	num?: number;
	current_operation_step_id?: number;
	current_operation_name?: string;
	status: TechMapAllStatus;
	created_at: string;
	storage_at?: string;
	current_details_count?: number;
	wasted?: number;
	remaining_count?: number;
	comments_count?: number;
};
export type ResponsePaginationApiListAppModelsTechMapTechMapTechMapForAll = {
	payload: TechMapForAll[];
	pages?: number;
	page?: number;
	total?: number;
};
export type TechMapAllSortingFields =
	| 'group'
	| 'number'
	| 'type'
	| 'symbol'
	| 'name'
	| 'num'
	| 'current_operation_name'
	| 'status'
	| 'created_at'
	| 'storage_at'
	| 'current_details_count'
	| 'wasted'
	| 'remaining_count';
export const {
	useGetMaterialsQuery,
	useLazyGetMaterialsQuery,
	useCreateMaterialMutation,
	useGetMaterialQuery,
	useLazyGetMaterialQuery,
	useGetOperationTypesQuery,
	useLazyGetOperationTypesQuery,
	useCreateOperationTypeMutation,
	useGetOperationTypeQuery,
	useLazyGetOperationTypeQuery,
	useGetOperationsQuery,
	useLazyGetOperationsQuery,
	useUpdateOperationsMutation,
	useCreateOperationMutation,
	useGetDefaultOperationsQuery,
	useLazyGetDefaultOperationsQuery,
	useGetCurrentOperationsNamesQuery,
	useLazyGetCurrentOperationsNamesQuery,
	useGetOperationQuery,
	useLazyGetOperationQuery,
	useGetOperationLinkHistoryQuery,
	useLazyGetOperationLinkHistoryQuery,
	useUpdateOperationsOperatorMutation,
	useCancelOperationMutation,
	useRenewalOperationMutation,
	useGetProductsQuery,
	useLazyGetProductsQuery,
	useUpdateProductMutation,
	useCreateProductMutation,
	useGetProductQuery,
	useLazyGetProductQuery,
	useDeleteProductMutation,
	useGetProductNamesForFilterQuery,
	useLazyGetProductNamesForFilterQuery,
	useGetProductSymbolForFilterQuery,
	useLazyGetProductSymbolForFilterQuery,
	useAddFileToDetailMutation,
	useDeleteFileForDetailMutation,
	useGetTechMapsQuery,
	useLazyGetTechMapsQuery,
	useUpdateTechMapMutation,
	useCreateTechMapMutation,
	useGetAllProductTechMapsForLinkPreformTechMapQuery,
	useLazyGetAllProductTechMapsForLinkPreformTechMapQuery,
	useGetUniqueProductCustomNumsQuery,
	useLazyGetUniqueProductCustomNumsQuery,
	useGetTechMapQuery,
	useLazyGetTechMapQuery,
	useGetTechMapGraphQuery,
	useLazyGetTechMapGraphQuery,
	useGetTechMapByNumberQuery,
	useLazyGetTechMapByNumberQuery,
	useChangeStatusMutation,
	useGetTechMapExcelMutation,
	useCreateLinkedTechMapWithNewMutation,
	useCreateLinkedTechMapWithExistedMutation,
	useGetBoundariesForUpdatingLinkTechMapQuery,
	useLazyGetBoundariesForUpdatingLinkTechMapQuery,
	useUpdateLinkTechMapMutation,
	useDeleteLinkTechMapMutation,
	useGetTechMapListExcelMutation,
	useGetTechMapStatusListQuery,
	useLazyGetTechMapStatusListQuery,
	useGetInProgressTechMapsQuery,
	useLazyGetInProgressTechMapsQuery,
	useChangeProductTechMapFileMutation,
	useCreateTechMapPrintPdfMutation,
	useGetProductTechMapCommentsQuery,
	useLazyGetProductTechMapCommentsQuery,
	useCreateCommentForTechMapMutation,
	useChangeCommentTextMutation,
	useDeleteCommentMutation,
	useGetStorageLogsQuery,
	useLazyGetStorageLogsQuery,
	useUpdateStorageLogMutation,
	useCreateStorageLogMutation,
	useGetStorageLogQuery,
	useLazyGetStorageLogQuery,
	useGetProfileQuery,
	useLazyGetProfileQuery,
	useUserUpdateMutation,
	useUserChangePasswordMutation,
	useAuthMutation,
	useRefreshTokenMutation,
	useLogoutQuery,
	useLazyLogoutQuery,
	useResetPasswordMutation,
	useAuthChangePasswordMutation,
	useGetNumsQuery,
	useLazyGetNumsQuery,
	usePutNumCatalogMutation,
	usePostNumCatalogMutation,
	useGetNumByIdQuery,
	useLazyGetNumByIdQuery,
	useDeleteNumCatalogMutation,
	useGetNumCatalogListExcelMutation,
	usePutSgdOutMutation,
	usePostSgdOutMutation,
	usePutSgdOutOperatorMutation,
	usePatchPlanOperatorMutation,
	usePutCustomMaterialMutation,
	usePutCustomProductMutation,
	useUpdateNumMutation,
	useGetEmployeesQuery,
	useLazyGetEmployeesQuery,
	useGetEmployeeInitialsQuery,
	useLazyGetEmployeeInitialsQuery,
	useGetEmployeeQuery,
	useLazyGetEmployeeQuery,
	useGetTechMapSymbolCatalogQuery,
	useLazyGetTechMapSymbolCatalogQuery,
	useUpdateMaterialMutation,
	useUpdateOperationTypeMutation,
	usePutPlanMutation,
	useCancelTechmapMutation,
	useDeleteTechmapMutation,
	useGetUsersQuery,
	useLazyGetUsersQuery,
	useUpdateUserMutation,
	useCreateUserMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useDeleteUserByIdMutation,
	useCreateUserExecutorMutation,
	useFireUserMutation,
	useRestoreUserMutation,
	useActivateUserMutation,
	useGetUserRolesMappingQuery,
	useLazyGetUserRolesMappingQuery,
	useUpdateEmployeeCatalogMutation,
	useCreateEmployeeCatalogMutation,
	useDeleteEmployeeCatalogMutation,
	useGetIssuesQuery,
	useLazyGetIssuesQuery,
	useGetIssueStuckOperationDetailsQuery,
	useLazyGetIssueStuckOperationDetailsQuery,
	useGetIssueWastedLimitDetailsQuery,
	useLazyGetIssueWastedLimitDetailsQuery,
	useCreateCommentForIssueMutation,
	useChangeIssueStatusToProgressMutation,
	useChangeIssueStatusToDoneMutation,
	useGetIssueStatusMappingQuery,
	useLazyGetIssueStatusMappingQuery,
	useGetIssueTypeMappingQuery,
	useLazyGetIssueTypeMappingQuery,
	useGetIssueListExcelMutation,
	useGetUserNotificationsQuery,
	useLazyGetUserNotificationsQuery,
	useGetNotificationTypeMappingQuery,
	useLazyGetNotificationTypeMappingQuery,
	useGetCountOfUnreadQuery,
	useLazyGetCountOfUnreadQuery,
	useReadNotificationMutation,
	useGetFileQuery,
	useLazyGetFileQuery,
	useGetSuppliersQuery,
	useLazyGetSuppliersQuery,
	useGetManufacturesQuery,
	useLazyGetManufacturesQuery,
	useGetInvoicesFilterByNumberQuery,
	useLazyGetInvoicesFilterByNumberQuery,
	useGetInvoicesFilterByDateQuery,
	useLazyGetInvoicesFilterByDateQuery,
	useGetAssembliesQuery,
	useLazyGetAssembliesQuery,
	useCreateAssemblyMutation,
	useGetAssemblyByIdQuery,
	useLazyGetAssemblyByIdQuery,
	useUpdateAssemblyMutation,
	useDeleteAssemblyMutation,
	useAddFileToAssemblyMutation,
	useDeleteFileForAssemblyMutation,
	useGetAssemblyTechMapsQuery,
	useLazyGetAssemblyTechMapsQuery,
	useCreateAssemblyTechMapMutation,
	useGetAllFreeAssemblyTechMapsForLinkQuery,
	useLazyGetAllFreeAssemblyTechMapsForLinkQuery,
	useGetAssemblyTechMapsExcelQuery,
	useLazyGetAssemblyTechMapsExcelQuery,
	useGetAssemblyTechMapByIdQuery,
	useLazyGetAssemblyTechMapByIdQuery,
	useUpdateAssemblyTechMapMutation,
	useDeleteAssemblyTechMapMutation,
	useGetAssemblyTechMapByNumberQuery,
	useLazyGetAssemblyTechMapByNumberQuery,
	useGetAssemblyTechMapCommentsQuery,
	useLazyGetAssemblyTechMapCommentsQuery,
	useCreateCommentForAssemblyTechMapMutation,
	useChangeCommentTextForAssemblyTechMapMutation,
	useDeleteCommentForAssemblyTechMapMutation,
	useChangeAssemblyTechMapFileMutation,
	useCancelAssemblyTechMapMutation,
	useGetPreformsQuery,
	useLazyGetPreformsQuery,
	useCreatePreformMutation,
	useGetPreformByIdQuery,
	useLazyGetPreformByIdQuery,
	useUpdatePreformMutation,
	useDeletePreformMutation,
	useAddFileToPreformMutation,
	useDeleteFileForPreformMutation,
	useGetPreformTechMapsQuery,
	useLazyGetPreformTechMapsQuery,
	useCreatePreformTechMapMutation,
	useGetPreformTechMapsExcelQuery,
	useLazyGetPreformTechMapsExcelQuery,
	useGetUniquePreformCustomNumsQuery,
	useLazyGetUniquePreformCustomNumsQuery,
	useGetPreformTechMapByIdQuery,
	useLazyGetPreformTechMapByIdQuery,
	useUpdatePreformTechMapMutation,
	useDeletePreformTechMapMutation,
	useGetPreformTechMapByNumberQuery,
	useLazyGetPreformTechMapByNumberQuery,
	useChangePreformTechMapFileMutation,
	useCancelPreformTechMapMutation,
	useGetAllCatalogNamesQuery,
	useLazyGetAllCatalogNamesQuery,
	useGetAllCatalogSymbolsQuery,
	useLazyGetAllCatalogSymbolsQuery,
	useGetUniqueCustomNumsQuery,
	useLazyGetUniqueCustomNumsQuery,
	useGetAllTechMapsQuery,
	useLazyGetAllTechMapsQuery,
	useGetAllTechMapsExcelQuery,
	useLazyGetAllTechMapsExcelQuery,
	usePostSeedDbMutation,
	useSeedDefaultProductsMutation,
	useSeedDefaultNumMutation,
	useSeedDefaultEmployeeMutation,
	useSeedUsersMutation,
	useRefreshCurrentOperationMutation,
	useRefreshCurrentDetailsCountMutation,
	useRefreshWastedMutation,
	useRefreshQrMutation,
} = injectedRtkApi;
